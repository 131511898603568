import React from "react";
import { stepsPropsType } from "..";
import frontEnd from "@assets/survey/step1/frontEnd.svg";
import backend from "@assets/survey/step1/backend.svg";
import fullStack from "@assets/survey/step1/fullStack.svg";
import employee from "@assets/survey/step1/employee.svg";
import techLeader from "@assets/survey/step1/techLeader.svg";
import manager from "@assets/survey/step1/manager.svg";
import consultant from "@assets/survey/step1/consultant.svg";
import student from "@assets/survey/step1/student.svg";
import intern from "@assets/survey/step1/intern.svg";
import "../Survey.css";

const items = [
  { id: 1, icon: frontEnd, title: "Frontend Developer" },
  { id: 2, icon: backend, title: " Backend Developer" },
  { id: 3, icon: fullStack, title: "Full Stack Developer" },
];

const itemsSubSteps = [
  { id: 1, icon: techLeader, title: "Tech Lead" },
  { id: 2, icon: manager, title: "Management" },
  { id: 3, icon: consultant, title: "Consultant" },
  { id: 4, icon: employee, title: "Employee" },
  { id: 5, icon: student, title: "Student" },
  { id: 6, icon: intern, title: "Intern" },
];

const SurveyStepOne = ({
  formData,
  setFormData,
  setActiveAlert,
}: stepsPropsType) => {
  const setData = (title: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        jobUser: title,
      };
    });
  };

  const setDataSubStep = (title: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        specialty: title,
      };
    });
  };

  return (
    <div className="stepBody stepOne">
      <div>
        <h1 className="title">
          1. I am a
          <hr />
        </h1>
      </div>

      <ul>
        {items.map((item) => {
          return (
            <li key={item.id}>
              <label className="labelCard">
                <input
                  type="radio"
                  name="stepOne"
                  onChange={() => {
                    setData(item.title);
                    setActiveAlert(false);
                  }}
                  defaultChecked={formData.jobUser === item.title}
                  value={""}
                />
                <span className="checkmark"></span>
                <div className="stepContent  stepOne__content">
                  <img src={item.icon} alt="icon" />
                  <h4>{item.title}</h4>
                </div>
              </label>
            </li>
          );
        })}
      </ul>

      {formData.jobUser && (
        <div className="subSteps">
          <div>
            <h1>2.What is my responsibility?</h1>
          </div>
          <ul>
            {itemsSubSteps.map((itemSubStep) => {
              return (
                <li key={itemSubStep.id}>
                  <label className="labelCard">
                    <input
                      type="radio"
                      name="subStepOne"
                      onChange={() => {
                        setDataSubStep(itemSubStep.title);
                        setActiveAlert(false);
                      }}
                      defaultChecked={formData.specialty === itemSubStep.title}
                      value={""}
                    />
                    <span className="checkmark"></span>
                    <div className="stepContent stepOne__content">
                      <img src={itemSubStep.icon} alt="icon" />
                      <h4>{itemSubStep.title}</h4>
                    </div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SurveyStepOne;

import React from "react";

interface WebflowPlanType {
  info: string;
  detail: string;
  price: number;
  name: string;
  properties: Array<string[]>;
  cta: string;
}

import { BsCheckCircleFill } from "react-icons/bs";
// const App = ({ message }: AppProps) => <div>{message}</div>;
const WebflowPlan = ({ plan }: { plan: WebflowPlanType }) => {
  return (
    <div className="flex gap-4 flex-col m-[35px] md:m-0 md:w-[40rem] h-auto bg-white text-left p-8 rounded-lg md:rounded-none  md:first:rounded-l-lg md:last:rounded-r-lg border-[#DDE0E2]-1">
      <p className="text-2xl font-semibold ">{plan.name}</p>
      <p className="">
        <span className="font-bold text-[48px] mr-2">
          {"$" + plan.price + "/month"}
        </span>
      </p>
      <p className="text-[16px]">{plan.info}</p>
      <div className="text-[14px]">
        {plan.properties.map(([text, color]) => (
          <div
            className="flex justify-start gap-1.5 mb-3 items-center"
            key={text}
          >
            {color === "green" ? (
              <BsCheckCircleFill className="text-green-600" />
            ) : (
              <BsCheckCircleFill className="text-red-200" />
            )}
            <p className="font-light">{text}</p>
          </div>
        ))}
      </div>
      <br />
      <button
        className="border border-black bg-white text-black p-3 w-2/3 ml-auto mr-auto rounded focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 hover:bg-white-800   dark:bg-white dark:hover:bg-white-700 dark:focus:ring-white-900 disabled"
        onClick={() => {
          window.open(plan.cta);
        }}
      >
        Start 7-day free trial
      </button>
    </div>
  );
};
export { WebflowPlan, WebflowPlanType };

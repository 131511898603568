import cssLogo from "@assets/csslogo.png";
import jsxLogo from "@assets/jsxlogo.png";
import sasslogo from "@assets/sasslogo.png";
import styledLogo from "@assets/styledLogo.png";
import inlineCssLogo from "@assets/inlinecss.png";
import tsxLogo from "@assets/tsxlogo.png";
import materialDesignLogo from "@assets/material-design.png";
import tailwindLogo from "@assets/tailwind.png";
import bootstrapLogo from "@assets/bootstrap.svg";
import {
  BOOTSTRAP_LIBRARY_COMPONENT_ID,
  MUI_LIBRARY_COMPONENT_ID,
} from "./consts";

export const stylesheetOptions = [
  { label: "CSS", value: "CssModules", enabled: true, logo: cssLogo },
  {
    label: "Inline CSS",
    value: "InlineStyling",
    enabled: true,
    logo: inlineCssLogo,
  },
  { label: "SCSS", value: "Scss", enabled: true, logo: sasslogo },
  {
    label: "Styled Components",
    value: "StyledComponents",
    enabled: true,
    logo: styledLogo,
  },
];

export const libraryOptions = [
  { label: "None", value: null, enabled: true, logo: null },
  {
    label: "Material UI",
    value: MUI_LIBRARY_COMPONENT_ID,
    enabled: true,
    logo: materialDesignLogo,
  },
  {
    label: "Bootstrap",
    value: BOOTSTRAP_LIBRARY_COMPONENT_ID,
    enabled: true,
    logo: bootstrapLogo,
  },
  { label: "Tailwind", value: -1, enabled: false, logo: tailwindLogo },
];

export const languageOptions = [
  { label: "JSX", value: "jsx", enabled: true, logo: jsxLogo },
  { label: "TSX", value: "tsx", enabled: true, logo: tsxLogo },
];

export const unitsOptions = [
  {
    label: "Margin Unit",
    options: [
      { value: "px", label: "px" },
      { value: "em", label: "em" },
      { value: "rem", label: "rem" },
    ],
  },
  {
    label: "Padding Unit",
    options: [
      { value: "px", label: "px" },
      { value: "em", label: "em" },
      { value: "rem", label: "rem" },
    ],
  },
];

import arrowBack from "@assets/arrowBack.svg";
import { selectDocumentId, selectUser, setNodeId } from "@store/plugin";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { store } from "@store/index";
import { figmaFrameSubmit, trackEvent } from "@utils/analytics";

const PreGeneration = () => {
  const [selectedDesign, setSelectedDesign] = useState("");
  const user = useSelector(selectUser);
  const documentId = useSelector(selectDocumentId) || "";
  const navigate = useNavigate();
  const location: any = useLocation();
  const figmaElementId = (location.state["figmaElementId"] as string) || "";
  const handleClick = (type: string) => {
    trackEvent(figmaFrameSubmit, {
      doc_id: documentId,
      node_id: figmaElementId,
      design_label: selectedDesign,
    });
    store.dispatch(setNodeId(figmaElementId));
    localStorage.setItem("selectedTab", type);
    if (user?.completed_first_codegen) navigate("/codegen/" + figmaElementId);
    else navigate("/settings", { state: { figmaElementId } });
  };

  const onCheckboxChange = (key: string) => {
    setSelectedDesign(key);
  };

  return (
    <div
      className={`h-screen w-full overflow-y-auto overflow-x-hidden pl-[15px] pr-[15px]`}
    >
      <Link
        to="/ready"
        className="flex items-center my-[22px]"
        onClick={() => {
          localStorage.setItem("selectedTab", "Code");
        }}
      >
        <img
          src={arrowBack}
          alt="<"
          className="w-[18px] h-[15px] mr-[10px] mb-[15px]"
        />
        <span className="flex-col">
          <div className="text-[18px] font-[700] leading-[21px]">
            What kind of design are you working on today?
          </div>
        </span>
      </Link>

      <div>
        <div className="flex flex-row items-center mb-[23px]">
          <input
            type="checkbox"
            id="dashboard"
            className="accent-check w-[18px] cursor-pointer h-[18px] mr-[12px]"
            onChange={(e) => onCheckboxChange("dashboard")}
            checked={selectedDesign === "dashboard"}
          />
          <label
            htmlFor="dashboard"
            className="text-[16px] cursor-pointer font-[600]"
          >
            Dashboard Page
          </label>
        </div>
        <div className="flex flex-row items-center mb-[23px]">
          <input
            type="checkbox"
            id="landing"
            className="accent-check w-[18px] cursor-pointer h-[18px] mr-[12px]"
            onChange={(e) => onCheckboxChange("landing")}
            checked={selectedDesign === "landing"}
          />
          <label
            htmlFor="landing"
            className="text-[16px] cursor-pointer font-[600]"
          >
            Landing Page
          </label>
        </div>
        <div className="flex flex-row items-center mb-[23px]">
          <input
            type="checkbox"
            id="mobile"
            className="accent-check w-[18px] cursor-pointer h-[18px] mr-[12px]"
            onChange={(e) => onCheckboxChange("mobile")}
            checked={selectedDesign === "mobile"}
          />
          <label
            htmlFor="mobile"
            className="text-[16px] cursor-pointer font-[600]"
          >
            Mobile View
          </label>
        </div>
        <div className="flex flex-row items-center mb-[23px]">
          <input
            type="checkbox"
            id="other"
            className="accent-check w-[18px] cursor-pointer h-[18px] mr-[12px]"
            onChange={(e) => onCheckboxChange("other")}
            checked={selectedDesign === "other"}
          />
          <label
            htmlFor="other"
            className="text-[16px] cursor-pointer font-[600]"
          >
            Pricing / about / other website pages
          </label>
        </div>
        <div className="flex flex-row items-center mb-[23px]">
          <input
            type="checkbox"
            id="individual"
            className="accent-check w-[18px] cursor-pointer h-[18px] mr-[12px]"
            onChange={(e) => onCheckboxChange("individual")}
            checked={selectedDesign === "individual"}
          />
          <label
            htmlFor="individual"
            className="text-[16px] cursor-pointer font-[600]"
          >
            Individual components
          </label>
        </div>
        <div className="flex flex-row items-center mb-[23px]">
          <input
            type="checkbox"
            id="login_signup"
            className="accent-check w-[18px] cursor-pointer h-[18px] mr-[12px]"
            onChange={(e) => onCheckboxChange("login_signup")}
            checked={selectedDesign === "login_signup"}
          />
          <label
            htmlFor="login_signup"
            className="text-[16px] cursor-pointer font-[600]"
          >
            Login / signup / other input form
          </label>
        </div>
      </div>

      <div className="fixed w-full right-0 bottom-0 p-[30px]">
        <button
          //   disabled={!image.length}
          onClick={() => handleClick("Code")}
          className="cursor-pointer shadow-button-shadow justify-center inline-flex bg-[#FFD803] disabled:opacity-50 items-center rounded-lg py-[12px] w-full"
        >
          <span className="text-[16px] text-black font-[500]">
            Generate Code
          </span>
        </button>
      </div>
    </div>
  );
};

export default PreGeneration;

import CloseIcon from "@assets/close-icon.png";
import { selectUser } from "@store/plugin";
import { codeFeedback, trackEvent } from "@utils/analytics";
import { sendinblueApi } from "@utils/api";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// The container with the button to show everywhere
export const CommentPage = ({
  setOpen,
  setPage,
  rating,
}: {
  setOpen: Function;
  setPage: Function;
  rating: number;
}) => {
  const [comments, setComments] = useState("");
  const user = useSelector(selectUser);
  const userEmail = user?.email || "unknown";

  return (
    <div
      className={`bg-white rounded-xl py-[14px] px-[17px] h-[241px] w-[361px] text-center align-middle`}
    >
      <div className="flex flex-row justify-between">
        <div className="font-medium text-[13px]">
          <b className="text-[#007ACC]">Thanks!</b> If you have any comments,
          let us know{" "}
        </div>
        <div onClick={() => setOpen(false)} className="">
          <img src={CloseIcon} className="h-[20px]" />
        </div>
      </div>
      <div className="flex-col mt-[15px] mr-[20px]">
        <textarea
          autoFocus
          className="h-[126px] w-full bg-[#EEEEEE] p-[5px]"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <button
          className={`float-right px-[18px] py-[7px] rounded-xl text-[13px] text-white ${
            comments.length ? "" : "opacity-40"
          } bg-[#007ACC]`}
          disabled={!comments.length}
          onClick={() => {
            trackEvent(codeFeedback, { feedback: comments, rating });
            sendinblueApi(userEmail, { CODEFEEDBACK: comments });
            setPage(3);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

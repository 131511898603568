import React, { useState } from "react";

import selectedIcon from "@assets/selectedIcon.png";
import { SettingCard } from "./SettingsCard";

export const Options = ({
  label,
  options,
  selected,
  setOption,
}: {
  label: string;
  options: any;
  selected: String;
  setOption: any;
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectedOption = options.find((option) => option.value === selected);
  return (
    <SettingCard label={label}>
      <div className="bg-[#F5F5F5] shadow-md flex  justify-center rounded-md">
        <div
          onClick={() => setShowOptions(!showOptions)}
          className={`p-[10px] text-[12px] font-bold inline-flex justify-center h-fit  ${
            showOptions ? "bg-lime-400" : "bg-transparent"
          }`}
        >
          {!showOptions && selectedOption.logo && (
            <div>
              <img
                src={selectedOption.logo}
                className="w-[14px] h-[14px]"
                alt="style logo"
              ></img>
            </div>
          )}
          <div className="uppercase ml-[5px] cursor-pointer h-fit">
            {selectedOption.label}
          </div>
        </div>
        {showOptions &&
          options
            .filter(
              (option) =>
                option.value !== selected &&
                (option.enabled === undefined || option.enabled)
            )
            .map((option) => (
              <div
                className="p-[10px] h-fit text-[12px] font-bold inline-flex justify-evenly border-t-black-[70] border-t-[1px]"
                onClick={() => {
                  setOption(option.value);
                  setShowOptions(false);
                }}
              >
                <div>
                  <img
                    src={selectedIcon}
                    className="w-[12px] h-[12px] hidden"
                    alt="style logo"
                  ></img>
                </div>

                <div className="uppercase ml-[5px] cursor-pointer">
                  {option.label}
                </div>
              </div>
            ))}
      </div>
    </SettingCard>
  );
};

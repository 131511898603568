import { configureStore } from "@reduxjs/toolkit";
import pluginSlice from "./plugin";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";

export const LOCAL_STORAGE_KEY = "ccat-plugin";
const config = {
  key: LOCAL_STORAGE_KEY,
  storage: localStorage,
};
const persistedPlugin = persistReducer(config, pluginSlice);

export const store = configureStore({
  reducer: {
    plugin: persistedPlugin,
  },
});

export type RootState = ReturnType<typeof store.getState>;

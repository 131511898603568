import React, { ReactNode } from "react";

export const SettingCard = ({
  label,
  children,
}: {
  label: any;
  children: ReactNode;
}) => {
  return (
    <div className="px-[20px] w-[17%] flex items-center text-center mr-[10px] border-r-[1px] border-r-[#00000012] border-solid border-1">
      <div className="w-fit text-[12px] mr-[8px] font-bold">{label}</div>
      {children}
    </div>
  );
};

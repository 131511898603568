import React from "react";
import { stepsPropsType } from "..";
import itemone from "@assets/survey/step2/itemone.png";
import itemtwo from "@assets/survey/step2/itemtwo.png";
import itemthree from "@assets/survey/step2/itemthree.png";
import itemfour from "@assets/survey/step2/itemfour.png";
import "../Survey.css";

const items = [
  { id: 1, icon: itemone, title: "For the first time" },
  { id: 2, icon: itemtwo, title: "For a few of my current and past projects" },
  { id: 3, icon: itemthree, title: "For all my past projects" },
  {
    id: 4,
    icon: itemfour,
    title: "Living and breathing React ever since I can remember",
  },
];

const SurveyStepTwo = ({
  formData,
  setFormData,
  setActiveAlert,
}: stepsPropsType) => {
  const setData = (subTitle: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        familiarity: subTitle,
      };
    });
    setActiveAlert(false);
  };

  return (
    <div className="stepBody steptwo">
      <div>
        <h1>
          2. How familiar are you with <p>React.js</p>?
        </h1>
        <div className="subTitle">
          <p>I am using React</p>
          <hr />
        </div>
      </div>

      <ul>
        {items.map((item) => {
          return (
            <li key={item.id}>
              <label className="labelCard">
                <input
                  type="radio"
                  name="radio"
                  onChange={() => {
                    setData(item.title);
                    setActiveAlert(false);
                  }}
                  defaultChecked={formData.familiarity === item.title}
                  value={item.title}
                />
                <span className="checkmark"></span>
                <div className="steptwo--content">
                  <img src={item.icon} alt="" />
                  <h4>{item.title}</h4>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SurveyStepTwo;

import CheckIcon from "@assets/check-fill.png";
import SlackIcon from "@assets/slack-icon.png";
import CircularLoader from "@components/CircularLoader";
import Footer from "@components/Footer";
import { LogoutModal } from "@components/LogoutModal";
import { selectAuthToken } from "@store/plugin";
import { accesscodeInput, joinSlack, trackEvent } from "@utils/analytics";
import { getUserInfo, sendinblueApi, verifyAccessToken } from "@utils/api";
import { storeUser } from "@utils/auth";
import { slackLink } from "@utils/consts";
import { openInNewTab } from "@utils/redirects";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const RequestAccess = () => {
  const [text, setText] = useState("");
  const [subtext, setSubText] = useState("");
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const authToken = useSelector(selectAuthToken);
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state["user"];

  const handleSubmit = () => {
    trackEvent(accesscodeInput);
    sendinblueApi(user.email, { ACCESSCODEINPUT: true });
    if (authToken) {
      setSubText("");
      setShowLoader(true);
      verifyAccessToken(text, authToken)
        .then((res: any) => {
          if (res.data.success) {
            getUserInfo(authToken).then((userInfo) => {
              const user = userInfo.data;
              if (user && user.id) {
                storeUser(user);
              }
            });
            navigate("/setup");
          }
        })
        .catch((error: any) => {
          const message =
            error?.response?.data?.detail?.message || "Something went wrong";
          setSubText(message);
          setShowLoader(false);
        });
    }
  };

  const handlePaste = async () => {
    try {
      document.execCommand("paste");
    } catch (e) {}
    try {
      let text = await navigator.clipboard.readText();
      setText(text);
    } catch (e) {}
  };
  return (
    <div className="h-screen w-full">
      {confirmLogout && <LogoutModal setOpen={setConfirmLogout} />}
      <div className="flex flex-col mx-[35px]">
        <div className="inline-flex pt-[40px] items-center">
          <div className="w-[263px] text-[25px] font-[700] mx-auto mt-[10px]">
            Join our Community
          </div>
          <div
            className="text-[14px] font-normal underline text-blue-500 cursor-pointer flex-nowrap"
            onClick={() => setConfirmLogout(true)}
          >
            Log Out
          </div>
        </div>
        <div className="text-[16px] text-center my-[20px] font-[400]">
          Please join our
          <span
            className="inline-flex items-start mx-[1px] text-[#0075FF] font-[500]"
            onClick={() => {
              trackEvent(joinSlack);
              openInNewTab(slackLink);
            }}
          >
            <img src={SlackIcon} className="h-[16px] mx-[4px] mt-[1px]" />
            Slack Channel
          </span>{" "}
          to get your free Access Code
        </div>
        <div className="text-[16px] my-[20px]">
          <b>Step 1</b> - Join Slack Channel
        </div>
        <button
          onClick={() => {
            trackEvent(joinSlack);
            openInNewTab(slackLink);
          }}
          className={`inline-flex justify-center mb-[20px] mx-auto py-[12px] w-full rounded-xl text-md font-medium text-black bg-[#FFD803]`}
        >
          <img src={SlackIcon} className="h-[16px] mx-[4px] mt-[3px]" /> Join
          Now
        </button>
        <div className="w-full border-b-2 border-dashed my-[20px]"></div>
        <div className="text-[16px] my-[20px] font-[500]">
          <b>Step 2</b> - Enter Access Code
        </div>
        <div className="inline-flex justify-between rounded-xl shadow-lg bg-white w-full py-[12px] px-[20px]">
          <input
            autoFocus
            className="appearance-none w-3/4 text-black leading-tight focus:outline-none"
            type={"text"}
            value={text}
            placeholder={"Enter Your Code"}
            onChange={(e) => setText(e.target.value)}
          />
          <button
            className="text-[#0075FF] text-[14px]"
            onClick={() => handlePaste()}
          >
            Paste
          </button>
        </div>

        <button
          disabled={showLoader}
          onClick={handleSubmit}
          className={`mt-[30px] mx-auto py-[12px] w-full rounded-xl text-md font-medium text-black bg-transparent border-[#FFD803] border-2`}
        >
          {showLoader ? (
            <CircularLoader
              width="w-4"
              height="h-4"
              color="text-gray-200"
              fill="fill-gray-600"
            />
          ) : (
            <span className="inline-flex justify-center">
              <img src={CheckIcon} className="h-[20px] mr-[10px]" /> Confirm
            </span>
          )}
        </button>
        <div className="text-red-700">{subtext}</div>

        <div className="text-[13px] font-normal text-left mt-[60px]">
          <span className="font-semibold">Why Join Slack?</span>
          <br />
          The community version is open to members of our slack workspace -
          'CopyCat Feedback.' Your participation in the community will help us
          serve you better.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RequestAccess;

import { formatCode } from "@helper/formatCode";
import { randomstr } from "@helper/functions";

export interface CodeResult {
  entry: Code;
  components: Code[];
  dependencies?: Dependancy[];
  externalFiles?: ExternalFile[];
  additionalImports?: string;
}

export interface ExternalFile {
  name: string;
  data: string;
  url?: string;
}

export interface Code {
  name: string;
  code: string;
  type: string;
  style: string;
  style_type?: string;
  id: null;
  images: ImageInfo[];
  imports: ImportInfo[];
}

export interface Dependancy {
  name: string;
  version: string;
}

interface ImportInfo {
  name: string;
  external?: boolean;
  path?: string;
}

interface ImageInfo {
  name: string;
  extension: string;
  id: string;
  url: string;
}

export class FolderItem {
  readonly name: string;
  readonly filetype: string;
  value?: string;
  readonly id: string;
  url?: string;
  parent: Folder | undefined;
  additionalImports?: string;
  getPath() {
    if (!this.parent) return "/";
    console.log(this.name, this.parent.getPath());
    return this.parent.getPath();
  }
  toJSON = (key: string) => {
    if (key === "parent") {
      return this.parent ? this.parent.name : "";
    }
    return this;
  };

  constructor(
    name: string,
    filetype: string,
    value?: string,
    url?: string,
    additionalImports?: string
  ) {
    this.name = name;
    this.filetype = filetype;
    this.value = value;
    this.url = url;
    this.additionalImports = additionalImports;
    this.id = randomstr();
  }
}
export class Folder {
  name: string;
  readonly contents: (FolderItem | Folder)[];
  id: string;
  parent: Folder | undefined;

  public addItem = (item: FolderItem | Folder) => {
    item.parent = this;
    this.contents.push(item);
  };
  public addItems = (items: (FolderItem | Folder)[]) => {
    items.forEach((i) => this.addItem(i));
  };

  public getPath = (): string => {
    if (!this.parent) return "/" + this.name;
    return this.parent.getPath() + "/" + this.name;
  };
  public toJSON = (key: string) => {
    if (key === "parent") {
      return this.parent ? this.parent.name : "";
    }
    return this;
  };

  public getItem = (path: string): Folder | FolderItem | null => {
    const paths = path.split("/");
    if (paths[1] === this.name && paths[2].includes(".")) {
      const found = this.contents.find(
        (item) => item.name === paths[2]
      ) as FolderItem;
      return found;
    }
    const folder = this.contents.find((item) => {
      if (item instanceof Folder) {
        return item.name === paths[2];
      }
      return false;
    }) as Folder;
    if (folder) {
      return folder.getItem(paths.slice(1).join("/"));
    }
    return null;
  };
  /**
   * Remove all contents with new contents
   * @param items new contents
   */
  replaceContents = (items: (FolderItem | Folder)[]) => {
    while (this.contents.length) {
      this.contents.pop();
    }
    this.addItems(items);
  };

  //  findItem = (name: string): FolderItem | undefined {
  //   const result = this.contents.find((item) => {
  //     if (item instanceof Folder) {
  //       return item.findItem(name);
  //     }
  //     return item.name === name;
  //   }) as FolderItem | undefined;
  //   return result;
  // }

  constructor(name: string, contents: (FolderItem | Folder)[]) {
    this.name = name;
    this.id = randomstr();
    this.contents = [];
    this.addItems(contents);
  }
}

export function newImageFolderItem(img: ImageInfo): FolderItem {
  return new FolderItem(
    `${img.name}.${img.extension}`,
    img.extension,
    img.id,
    img.url
  );
}

function generateReactFiles(
  code: Code,
  isComponent: boolean = false,
  additionalImports?: string
): FolderItem[] {
  const code_file = new FolderItem(
    `${code.name}.${code.type}`,
    code.type,
    "",
    "",
    additionalImports
  );
  const files = [code_file];
  const component_imports = code.imports.map((im) =>
    im.external
      ? `import ${im.name} from "${im.path}"`
      : isComponent
      ? `import ${im.name} from "./${im.name}"`
      : `import ${im.name} from "./components/${im.name}"`
  );
  // const image_imports = code.images.map((im) =>
  //   isComponent
  //     ? `import ${im.name} from  "../assets/${im.name}.${im.extension}"`
  //     : `import ${im.name} from  "./assets/${im.name}.${im.extension}"`
  // );

  const imports = [...component_imports];
  if (code.style.length && code.style_type) {
    const fileName = code.name + `.${code.style_type}`;
    const cssFile = new FolderItem(fileName, "css", code.style);
    files.push(cssFile);
    // imports.push(`import "./${fileName}" `);
  }
  code_file.value = imports.join("\n") + code.code;
  code_file.value = formatCode(code_file);
  return files;
}
export const resultToFolders = (results: CodeResult): Folder => {
  const componentFiles = results.components
    .map((c) => generateReactFiles(c, true, results.additionalImports))
    .flatMap((c) => c);
  const components = new Folder("components", componentFiles);
  const entryFiles = generateReactFiles(
    results.entry,
    false,
    results.additionalImports
  );
  const src = new Folder("src", [components, ...entryFiles]);
  return src;
};

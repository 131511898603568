import LoadingScreen from "@components/LoadingScreen";
import { logout } from "@utils/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate("/");
  }, []);

  return <LoadingScreen message={"Logging Out"} />;
};
export default Logout;

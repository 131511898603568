import { FeedbackWrapper } from "@components/Feedback";
import LoadingScreen from "@components/LoadingScreen";
import { Navbar } from "@components/Navbar";
import { SettingsWidget } from "@components/SettingsWidget/Widget";
import { store } from "@store/index";
import {
  selectBuildSessionID,
  selectUser,
  setBuildSessionID,
} from "@store/plugin";
import { buildInitialize, syncFS } from "@utils/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  CodeResult,
  Folder,
  FolderItem,
  newImageFolderItem,
  resultToFolders,
} from "./helpers";
import { LeftPanel } from "./LeftPanel";
import { RightPanel } from "./RightPanel";

const fetchAssets = (code: CodeResult): FolderItem[] => {
  // get all the images in our code
  const images = code.components.flatMap((c) => c.images.map((im) => im));
  images.push(...code.entry.images);

  // Convert into dictionary of  ImageID : FolderItems
  const imageDict: Map<string, FolderItem> = new Map();

  images.forEach((image) => imageDict.set(image.id, newImageFolderItem(image)));

  const assetContents: FolderItem[] = Array.from(imageDict.values());

  return assetContents;
};

const CodeResults = () => {
  const location = useLocation();
  const code = location.state["code"] as CodeResult;
  const [openSettings, setOpenSettings] = useState(false);
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const user = useSelector(selectUser);

  const [codeType, setCodeType] = useState(
    user?.user_config?.outputLanguage || "tsx"
  );
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [selected, setSelectedItem] = useState(
    new Folder("", []) as FolderItem | Folder
  );
  const sessionID = useSelector(selectBuildSessionID);
  const setFile = (_: FolderItem) => {};
  const [codeFolder, setCodeFolder] = useState<Folder | null>(null);

  useEffect(() => {
    if (codeFolder) {
      return;
    }
    const folder = resultToFolders(code);
    setCodeFolder(folder);
    if (folder.getItem(`/src/App.jsx`))
      setSelectedItem(folder.getItem(`/src/App.jsx`) as FolderItem);
    else if (folder.getItem(`/src/App.tsx`))
      setSelectedItem(folder.getItem(`/src/App.tsx`) as FolderItem);
    setAssetsLoading(true);
  }, [user]);

  // Get SessionID
  useEffect(() => {
    // if (sessionID) {
    //   return;
    // }
    buildInitialize(
      code.dependencies,
      code.externalFiles,
      codeType,
      user?.user_config?.baseTextSize
    ).then(
      (x) => {
        const sessionID = x.data["SessionID"];
        console.info("Got SessionID", sessionID);
        store.dispatch(setBuildSessionID(sessionID));
      },
      (err) => {
        console.error("err", err);
      }
    );
  }, []);

  // Sync Files on Change`of code or sessionID
  useEffect(() => {
    if (!sessionID || !codeFolder || codeFolder.contents.length === 0) {
      return;
    }
    syncFS(sessionID, codeFolder);
  }, [codeFolder, sessionID]);

  useEffect(() => {
    if (!assetsLoading || !codeFolder) {
      return;
    }
    const contents = fetchAssets(code);
    const newFolder = { ...codeFolder } as Folder;
    const assets = new Folder("assets", contents);
    newFolder.replaceContents([assets, ...codeFolder.contents]);
    setCodeFolder(newFolder);
    setAssetsLoading(false);
  }, [assetsLoading]);

  if (!codeFolder) return <></>;
  if (updatingSettings) return <LoadingScreen message="Updating Settings" />;
  return (
    <div className="h-screen">
      <Navbar />
      <div className="flex flex-row h-full">
        <FeedbackWrapper minimize={minimize} />
        <LeftPanel
          codeFolder={codeFolder}
          selected={selected}
          setSelectedItem={setSelectedItem}
          showLoader={assetsLoading}
          minimize={minimize}
          setMinimize={setMinimize}
          sessionID={sessionID!}
          setOpenSettings={setOpenSettings}
          openSettings={openSettings}
        />
        {!minimize && (
          <RightPanel
            selectedFile={selected}
            updateSelectedFile={setFile}
            assetsLoading={assetsLoading}
            setOpenSettings={setOpenSettings}
            openSettings={openSettings}
            setUpdatingSettings={setUpdatingSettings}
          />
        )}
      </div>
    </div>
  );
};

export default CodeResults;

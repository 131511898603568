import React from "react";
import materialSymbolschec from "@assets/materialSymbolschec.svg";
import "./PlanFeatures.css";
import { PlanDetails } from "..";

interface Props {
  plan: PlanDetails;
}

const PlanFeatures: React.FC<Props> = ({ plan }) => {
  return (
    <div className="group-1310 py-6">
      <span className="premium-features">What are you paying for?</span>
      {plan.featuresOffered.map((feature) => {
        return (
          <div className="feature-container">
            <img className="material-symbolschec" src={materialSymbolschec} />
            <span>{feature}</span>
          </div>
        );
      })}
    </div>
  );
};

export default PlanFeatures;

import arrowBack from "@assets/arrowBack.svg";
import CircularLoader from "@components/CircularLoader";
import { SettingsCard } from "@components/Settings/SettingsCard";
import {
  selectAuthToken,
  selectCodeSessionID,
  selectNodeId,
  selectUser,
} from "@store/plugin";
import { codeOutputSettingsSubmit, trackEvent } from "@utils/analytics";
import { getUserInfo, regenerateCodegen, saveUserConfig } from "@utils/api";
import { storeUser } from "@utils/auth";
import { fetchError } from "@utils/errors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  languageOptions,
  libraryOptions,
  stylesheetOptions,
} from "../../utils/settingsMetadata";
import OutputConfiguration from "./OutputConfiguration";

type settingsProps = {
  setOpenSettings: Function;
  setUpdatingSettings: Function;
};

const Settings = ({ setOpenSettings, setUpdatingSettings }: settingsProps) => {
  const user = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken);
  const sessionId = useSelector(selectCodeSessionID) || "";
  const navigate = useNavigate();
  const location: any = useLocation();
  const onPageCall = location.pathname === "/settings";
  const figmaElementId = useSelector(selectNodeId);
  const locationElementId = location.state["figmaElementId"] as string;
  const [showLoader, setShowLoader] = useState(false);

  const [baseTextSize, setBaseTextSize] = useState(
    user?.user_config.baseTextSize || 14
  );
  const [basePaddingUnit, setBasePaddingUnit] = useState(
    user?.user_config.paddingUnit || "px"
  );
  const [basePadding] = useState(14);
  const [baseMarginUnit, setBaseMarginUnit] = useState(
    user?.user_config.marginUnit || "px"
  );
  const [baseMargin] = useState(0);
  const [languageSelector, setLanguageSelector] = useState(
    languageOptions.find(
      (option) => option.value === user?.user_config.outputLanguage
    ) || languageOptions[0]
  );
  const [styleSheetSelector, setStyleSheetSelector] = useState(
    stylesheetOptions.find(
      (option) => option.value === user?.user_config.StyleSheetConfig
    ) || stylesheetOptions[0]
  );
  const [UILibrary, setUILibrary] = useState(
    libraryOptions.find(
      (option) => option.value == user?.user_config.library_component_id
    ) || libraryOptions[0]
  );

  const handleSubmit = async () => {
    setShowLoader(true);
    setUpdatingSettings(true);
    const data = {
      baseTextSize: baseTextSize,
      paddingUnit: basePaddingUnit,
      marginUnit: baseMarginUnit,
      outputLanguage: languageSelector.value,
      StyleSheetConfig: styleSheetSelector.value,
      library_component_id: UILibrary.value,
    };
    const eventData = {
      baseTextSize: baseTextSize,
      paddingUnit: basePaddingUnit,
      marginUnit: baseMarginUnit,
      outputLanguage: languageSelector.value,
      StyleSheetConfig: styleSheetSelector.value,
      library_component_id: UILibrary.value,
      library_component_name: UILibrary.label,
    };
    trackEvent(codeOutputSettingsSubmit, eventData, "plugin");
    if (authToken) {
      const response = await saveUserConfig(authToken, data);
      try {
        if (response.data === "ok") {
          const userResponse = await getUserInfo(authToken as string);
          const user = userResponse.data;
          if (user && user.id) {
            storeUser(user);
            if (onPageCall) {
              if (locationElementId) navigate("/codegen/" + locationElementId);
              else navigate("/ready");
            } else
              regenerateCodegen(authToken, sessionId).then((response) => {
                navigate(
                  `/codegenBrowser/${authToken}/${response.data.session_id}`
                );
              });
          }
        } else {
          setShowLoader(false);
          setUpdatingSettings(false);
        }
      } catch (e) {
        navigate("/error", { state: { errordata: fetchError(error) } });
      }
    }
  };

  return (
    <div
      className={`flex flex-col overflow-y-auto overflow-x-hidden pl-[15px] pr-[20px] ${
        !onPageCall ? "min-w-[856px]" : ""
      }`}
    >
      {onPageCall && (
        <Link
          to="/ready"
          // to="/pregeneration"
          className="flex items-center my-[22px]"
          onClick={() => {
            localStorage.setItem("selectedTab", "Code");
          }}
        >
          <img
            src={arrowBack}
            alt="<"
            className="w-[18px] h-[15px] mr-[10px] mb-[15px]"
          />
          <span className="flex-col">
            <div className="text-[18px] font-[700] leading-[21px]">
              Set your code format
            </div>
            <div className="text-[10px] font-[300] leading-[12px]">
              you can change this later
            </div>
          </span>
        </Link>
      )}
      <SettingsCard label="Output Configuration" enabled={true}>
        <OutputConfiguration
          onPageCall={onPageCall}
          baseTextSize={baseTextSize}
          basePadding={basePadding}
          baseMargin={baseMargin}
          styleSheetSelector={styleSheetSelector}
          setBaseTextSize={setBaseTextSize}
          setBaseMarginUnit={setBaseMarginUnit}
          setBasePaddingUnit={setBasePaddingUnit}
          setLanguageSelector={setLanguageSelector}
          setStyleSheetSelector={setStyleSheetSelector}
          setUILibrary={setUILibrary}
        />
      </SettingsCard>
      {/* <SettingsCard label="Component Libraries (Design System)" enabled={false}>
        <Workspace />
      </SettingsCard> */}
      <div className="my-[40px]"></div>
      <div className="fixed bottom-0 from-[#F5F5F5] bg-gradient-to-t pt-[20px] pb-[20px] min-w-[350px]">
        <div className="flex flex-row items-baseline justify-evenly">
          <button
            className={`cursor-pointer shadow-button-shadow after:border-black bg-[#F5F5F5] border-[1px] rounded-xl text-center text-[16px] font-[500] text-black py-[13px] px-[53px] ${
              !styleSheetSelector.enabled ? "opacity-50" : ""
            } `}
            type="button"
            onClick={(e) => {
              if (onPageCall) {
                if (locationElementId)
                  navigate("/codegen/" + figmaElementId, {
                    state: { type: "Code" },
                  });
                else navigate("/ready");
              } else setOpenSettings(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`cursor-pointer shadow-button-shadow bg-[#FFD803] rounded-xl text-center text-[16px] font-[500] text-black py-[13px] px-[53px] ${
              !styleSheetSelector.enabled ? "opacity-50" : ""
            } `}
            onClick={handleSubmit}
            disabled={!styleSheetSelector.enabled}
            title="By changing the settings, you need to select your component again, to be able to get new code results based on your new settings."
          >
            {showLoader ? (
              <CircularLoader
                width="w-4 mr-0"
                height="h-4"
                color="text-gray-200"
                fill="fill-gray-600"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;

import { LogoutModal } from "@components/LogoutModal";
import React, { useEffect, useState } from "react";
import PricingPlans from "@components/PricingPlans";
import { getSubscriptionStatus, getUserInfo } from "@utils/api";
import { useNavigate } from "react-router-dom";
import { logout, storeUser } from "@utils/auth";
import { identifyUser, paywallSeen, trackEvent } from "@utils/analytics";
import { setUser } from "@store/plugin";
import { fetchError } from "@utils/errors";

const BuyPremium = () => {
  const navigate = useNavigate();
  const [confirmLogout, setConfirmLogout] = useState(false);

  useEffect(() => {
    trackEvent(paywallSeen, {}, "plugin");
    const intervalId = setInterval(() => {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        getSubscriptionStatus(authToken!)
          .then((resp) => {
            if (resp.data.active) {
              getUserInfo(authToken).then((resp: any) => {
                const userInfo = {
                  id: resp.data.id,
                  handle: resp.data.handle,
                  img_url: resp.data.img_url,
                  email: resp.data.email,
                };

                identifyUser(userInfo);
                setUser(resp.data);
                storeUser(resp.data);
                clearInterval(intervalId);
                navigate("/setup");
              });
            }
          })
          .catch((error: any) => {
            clearInterval(intervalId);
            console.log("error verifying subscription status:", error);
            navigate("/error", {
              state: { errordata: fetchError(error) },
            });
          });
      } else {
        clearInterval(intervalId);
        logout();
        navigate("/");
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {confirmLogout && <LogoutModal setOpen={setConfirmLogout} />}
      <div className="flex-col w-full h-screen px-[30px] bg-[#F5F5F5]">
        <div className="inline-flex pt-[40px] items-center">
          <h2 className="w-[263px] text-[25px] font-[700] leading-[32px] text-black">
            Start free trial!
          </h2>
          <div
            className="text-[14px] font-[700] underline text-[#0075FF] cursor-pointer flex-nowrap"
            onClick={() => setConfirmLogout(true)}
          >
            Log Out
          </div>
        </div>
        <div className="pt-6">
          <PricingPlans viewMode="plugin" showTestimonials={false} />
        </div>
      </div>
    </>
  );
};

export default BuyPremium;

import PrivacyPolicyModal from "@components/PrivacyPolicy";
import WhySignInModal from "@components/WhySignIn";
import React, { useState } from "react";

const Footer = () => {
  const [whySignIn, setWhySignIn] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  return (
    <footer className="bottom-0 fixed text-center w-full">
      <div className="inline-flex py-[20px] text-black">
        <div className="text-[14px] font-[700] underline">
          <a href="https://www.copycat.dev/" target="_blank">
            Any Questions?
          </a>
        </div>
      </div>

      {whySignIn && <WhySignInModal handleClose={() => setWhySignIn(false)} />}
      {privacyPolicy && (
        <PrivacyPolicyModal handleClose={() => setPrivacyPolicy(false)} />
      )}
    </footer>
  );
};

export default Footer;

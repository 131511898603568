import { selectDocumentId, selectNodeId } from "@store/plugin";
import {
  codeResultAssetsSeen,
  codeResultComponentSeen,
  codeResultStyles,
  trackEvent,
} from "@utils/analytics";
import React, { useEffect, useState } from "react";
import { IoImagesOutline } from "react-icons/io5";
import { SiJavascript, SiSass, SiTypescript } from "react-icons/si";
import { VscCode, VscFileCode, VscSymbolColor } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Folder, FolderItem } from "../helpers";

const explorerStyle =
  " text-[13px] inline-flex items-center min-w-[150px] p-[3px]";
const hoverClasses = " hover:bg-gray-100 cursor-pointer";
const activeClasses = "bg-gray-200 text-black font-[700]";

const cssFileExtensions = ["css", "scss", "sass"];
const imageFileExtensions = ["png", "svg"];
const codeFileExtensions = ["jsx", "tsx", "ts", "js"];

const icons = new Map([
  ["png", <IoImagesOutline />],
  ["svg", <IoImagesOutline />],
  ["ts", <SiTypescript />],
  ["tsx", <SiTypescript />],
  ["js", <SiJavascript />],
  ["jsx", <SiJavascript />],
  ["code", <VscCode />],
  ["css", <VscSymbolColor />],
  ["scss", <VscSymbolColor />],
  ["sass", <SiSass />],
]);

type FileProps = {
  file: FolderItem;
  setSelectedItem: (item: FolderItem | Folder) => void;
  selectedItem: FolderItem | Folder;
};

export const FileMenuItem = ({
  file,
  setSelectedItem,
  selectedItem,
}: FileProps) => {
  const nodeId = useSelector(selectNodeId) || "";
  const documentId = useSelector(selectDocumentId) || "";
  // select Icon based on file type
  const icon = icons.get(file.filetype) || <VscFileCode />;
  // store if this File is selected
  const [active, setActive] = useState(false);
  // update internal selction state based on explorer selection
  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      setActive(selectedItem.id === file.id);
    }
  }, [selectedItem]);

  return (
    <div
      onClick={() => {
        if (cssFileExtensions.includes(file.filetype)) {
          trackEvent(codeResultStyles, {
            document_id: documentId,
            node_id: nodeId,
          });
          localStorage.setItem("selectedTab", "Code");
        }
        if (
          file.parent?.name == "components" &&
          codeFileExtensions.includes(file.filetype)
        )
          trackEvent(codeResultComponentSeen, {
            document_id: documentId,
            node_id: nodeId,
          });
        if (imageFileExtensions.includes(file.filetype))
          trackEvent(codeResultAssetsSeen);
        setSelectedItem(file);
      }}
      key={file.id}
      className={`${explorerStyle} ${
        active ? activeClasses : "font-[500]"
      } ${hoverClasses}`}
    >
      <span className="mr-1">{icon}</span>
      <span className="pr-2">{file.name}</span>
    </div>
  );
};

import unknownError from "@assets/unknownError.png";
// import { FeedbackWrapper } from "@components/Feedback";
// import { MessageView } from "@components/FeedBack/MessageView";
import { ErrorScreenData } from "@utils/types";
import React from "react";
import { To, useLocation, useNavigate } from "react-router-dom";

const ErrorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errordata = location.state["errordata"] as ErrorScreenData;

  return (
    <div className="flex h-screen flex-col justify-center items-center">
      {/* <FeedbackWrapper
        children={
          <MessageView
            headerText="Oh no! - We'd like to fix your issues."
            bodyText="Please let us know via our communities"
          />
        }
        position="top-[10px] right-[5px]"
      /> */}
      {errordata.graphic_url ? (
        <img src={errordata.graphic_url} />
      ) : (
        <img src={unknownError} className="w-full" />
      )}
      <h1 className="mt-5 px-5 text-[25px] font-semibold text-center text-black py-2">
        {errordata.message}
      </h1>
      <div className="mt-5 px-5 text-sm font-normal text-center text-gray-800 ">
        {errordata.sub_text}
      </div>

      <div className="w-[330px]">
        {errordata.buttons &&
          errordata.buttons.map(({ text, redirect_url }, index) => (
            <button
              key={index}
              onClick={() => {
                if (redirect_url == "/login") navigate("/");
                else if (typeof redirect_url === "number")
                  navigate(redirect_url);
                else navigate(redirect_url as To);
              }}
              className={`mt-5 px-10 py-2 text-medium rounded border border-black w-full ${
                index ? "bg-transparent text-black" : "bg-gray-900 text-white "
              }`}
            >
              {text}
            </button>
          ))}
      </div>
    </div>
  );
};

export default ErrorScreen;

import { MessageType } from "../utils/types";

export class KeyVal {
  key: string;
  value: any;

  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }
}

/**
 * A class to handle messages sent to the plugin
 */
export class PluginMessage {
  type: MessageType;
  data: KeyVal | any;

  constructor(type: MessageType, data: KeyVal | any) {
    this.type = type;
    this.data = data;
  }
}
/**
 * A interface to handle messages receieved from the plugin
 */
export interface PluginResponse {
  type: MessageType;
  data?: any;
  error: string | undefined;
}

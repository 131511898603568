import * as prettier from "prettier";
import flowparser from "prettier/parser-flow";
import htmlPretty from "prettier/parser-html";
import cssPretty from "prettier/parser-postcss";
import parserTypeScript from "prettier/parser-typescript";
import { FolderItem } from "../views/CodeResults/helpers";

export function formatCode(file: FolderItem): string {
  const options = {
    filepath: file.name,
    parser: file.filetype === "jsx" || file.filetype == "tsx" ? "flow" : "",
    plugins: [flowparser, htmlPretty, cssPretty, parserTypeScript],
  };
  try {
    return prettier.format(file.value!, options);
  } catch (error) {
    console.error(error);
  }

  return file.value!;
}

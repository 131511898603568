.success-login-browser {
  color: #555;
  width: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-family: Roboto, Arial;
  font-size: 3.313rem;
  font-weight: 400;
  line-height: 47px;
  display: flex;
  padding: 150px 50px;
}

.rectangle-10180 {
  color: #000;
  width: 100%;
  background-image: url("/src/assets/rectangle.png");
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  flex-direction: row;
  flex-basis: 21%;
  justify-content: flex-start;
  align-self: center;
  align-items: stretch;
  margin-bottom: 89px;
  font-size: 2.5rem;
  font-weight: 600;
  display: flex;
}

.rectangle-10181 {
  height: 100%;
  background-color: #ffd80366;
  border-radius: 10px;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 100px;
  display: flex;
}

.gem {
  width: 62px;
  height: 62px;
  object-fit: cover;
}

.welcome-to-the-premium-club {
  margin-left: 21px;
}

.thank-you-your-payment-was-succe {
  color: #000;
  font-weight: 700;
  line-height: 62px;
}

.you-can-now-get-back-to-figma-an {
  margin-bottom: 24px;
  margin-top: 40px;
  font-size: 1.25rem;
  line-height: 23px;
}

.tracking-number-243689 {
  margin-bottom: 41px;
  margin-left: 41px;
  font-size: 1rem;
  font-style: italic;
  line-height: 19px;
}

.flex-container {
  flex-direction: row;
  flex-basis: 5%;
  align-items: stretch;
  margin-bottom: 58px;
  display: flex;
}

.preview-btn {
  color: #000;
  text-align: center;
  height: 100%;
  background-color: #ffd803;
  border: 1.14286px solid;
  border-radius: 11px;
  flex-direction: row;
  flex-basis: 54%;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 4px;
  padding-right: 6px;
  font-family: Roboto, Arial;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.logosfigma {
  width: 17px;
  height: 25px;
  object-fit: cover;
  margin-left: 4px;
}

.copycat-plugin {
  margin-top: 2px;
}

.preview-btn-1 {
  text-align: center;
  color: #fff;
  height: 100%;
  background-color: #6930ca;
  border: 1.14286px solid;
  border-radius: 11px;
  flex-direction: row;
  flex-basis: 41%;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Roboto, Arial;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.logosslack-icon {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-left: 3px;
}

.support {
  margin-top: 1px;
}

.purple-text {
  color: #6930ca;
}

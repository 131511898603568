import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import { store } from "./store";
import "./main.css";
// import { PersistGate } from "redux-persist/integration/react";
// import LoadingScreen from "@components/LoadingScreen";
render(
  <Provider store={store}>
    {/* <PersistGate
      loading={<LoadingScreen message="Loading..." />}
      persistor={persistor}
    > */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById("app")
);

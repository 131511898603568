import * as React from "react";

export const UnitsInput = ({
  user,
  label,
  options,
  setUnit,
  attribute,
}: {
  user: any;
  label: string;
  options: any;
  setUnit: Function;
  attribute: string;
}) => {
  return (
    <div className="flex flex-col">
      <div className="text-[14px] font-[600] mb-[10px]">{label}</div>
      <div className="flex flex-row items-center">
        <div className="bg-check w-fit font-[500] rounded-[6px] mr-[50px] text-[16px] pr-[10px] flex flex-row items-center">
          <select
            defaultValue={user?.user_config[attribute] as string}
            className="bg-check focus:outline-none rounded py-1 px-2 text-[#555555] min-w-[98px] max-w-[128px]"
            onChange={(e) => {
              setUnit(e.target.value);
            }}
            style={{ boxShadow: "0px 4px 10px 0px #00000012" }}
          >
            {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

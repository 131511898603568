import slackLogo from "@assets/slack-logo.png";
import { joinSlack, trackEvent } from "@utils/analytics";
import { slackLink } from "@utils/consts";
import { openInNewTab } from "@utils/redirects";
import React from "react";

export const SlackButtonView = (_props: any) => {
  return (
    <img
      src={slackLogo}
      width={100}
      className="mx-auto cursor-pointer pt-1"
      onClick={() => {
        trackEvent(joinSlack);
        openInNewTab(slackLink);
      }}
    />
  );
};

import { MessageType } from "@utils/types";
import { PluginMessage, PluginResponse } from "./classes";

/**
 * Represents a subscriber which listens to a specific type of message
 */
class Subscriber {
  // The unique identifier of the subscriber
  identifier: number;
  // The callback to be called when a message is received
  callback: (data: any) => void;
  // Whether the subscriber should be unsubscribed after receiving a message
  single_event: boolean;

  constructor(
    identifier: number,
    callback: (data: PluginResponse) => void,
    single_event: boolean
  ) {
    this.identifier = identifier;
    this.callback = callback;
    this.single_event = single_event;
  }
}

const postMsgToPlugin = (pluginMessage) => {
  parent.postMessage({ pluginMessage, pluginId: "*" }, "*");
};

class MessageHandler {
  private static handler: MessageHandler;

  public static getInstance(): MessageHandler {
    return this.handler || (this.handler = new MessageHandler());
  }
  // array of subscribers to different message types
  private _subscribers = {};

  private _subscribercounter = 1;

  /**
   * Send Message to Figma
   * @param msg the message to send
   */
  public sendmsg = (msg: PluginMessage) => {
    postMsgToPlugin(msg);
  };
  /**
   * Send a message to Figma
   * @param type the message type to send
   * @param data the data to send
   */
  public send = (type: MessageType, data: any) => {
    const msg = new PluginMessage(type, data);
    postMsgToPlugin(msg);
  };

  /**
   * A function which wraps sending and recieving message of the same type in an async function
   * @param type the message to send
   * @param data any data to send
   * @returns async function with data or error
   */
  public async pluginAsyncRequest(
    type: MessageType,
    data: any
  ): Promise<PluginResponse> {
    return new Promise((resolve, reject) => {
      this.subscribe(
        type,
        (resp: PluginResponse) => {
          if (resp.error) {
            reject(resp);
            return;
          }
          resolve(resp);
        },
        true
      );
      this.send(type, data);
    });
  }

  /**
   * Subscribe to a message type
   * @param messageType The message type to subscribe to
   * @param callback the function to call when the message is received
   * @param single_event unsubscribe after receiving the message
   * @returns the ideentifier for the subscribers to unsubscribe from the message type
   */
  public subscribe(
    messageType: MessageType,
    callback: (data: PluginResponse) => void,
    single_event: boolean = false
  ) {
    const identifier = this._subscribercounter;
    const sub = new Subscriber(identifier, callback, single_event);
    this._subscribers[messageType].push(sub);
    this._subscribercounter += 1;
    return identifier;
  }

  // unsubscribe from a message type
  public unsubscribe(type: MessageType, identifier: number) {
    this._subscribers[type] = this._subscribers[type].filter(
      (subscriber) => subscriber.identifier !== identifier
    );
  }
  // internal function to handle incoming messages from the plugin
  private handleMessage(message: PluginMessage) {
    if (process.env.NODE_ENV !== "production") {
      if (this.checkMessageType(message) === false) {
        return;
      }
    }
    // Send messages to all the subscribers of that message type
    this._subscribers[message.type].forEach((subscriber: Subscriber) => {
      subscriber.callback(message);
      if (subscriber.single_event) {
        this.unsubscribe(message.type, subscriber.identifier);
      }
    });
  }

  private checkMessageType(message: PluginMessage): boolean {
    if (
      (Object.keys(MessageType) as Array<String>).find(
        (x) => x === message.type
      ) == undefined
    ) {
      console.error(`Message type ${message.type} is not handled`);
      return false;
    }
    return true;
  }
  constructor() {
    // initialize an array for all the different message types
    Object.values(MessageType).forEach((key) => {
      this._subscribers[key] = [];
    });
    onmessage = (event) => {
      const message: PluginMessage = event.data.pluginMessage;
      if (message) {
        this.handleMessage(message);
      }
    };
  }
}

const PluginMessageHandler = MessageHandler.getInstance();
export default PluginMessageHandler;

import { authParams, figmaOauthUrl } from "@utils/consts";
import { trackEvent, vscodeButtonClick } from "./analytics";
import { StateData } from "@utils/types";
import { useSelector } from "react-redux";
import { selectDocumentId, selectNodeId } from "@store/plugin";

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const redirectToFigmaAuth = (state: StateData) => {
  window.open(
    `${figmaOauthUrl}?client_id=${authParams.client_id}&redirect_uri=${
      authParams.redirect_uri
    }&scope=${authParams.scope}&state=${JSON.stringify(state)}&response_type=${
      authParams.response_type
    }&type=${authParams.type}`,
    "_blank"
  );
};

export const redirectToVsCodeTrigger = (
  codeSessionId: string,
  buildSessionId: string,
  authToken: string,
  documentId: string,
  nodeId: string
) => {
  trackEvent(vscodeButtonClick, { document_id: documentId, node_id: nodeId });
  window.open(
    authParams.redirect_uri?.replace(
      "login",
      `vscode/${codeSessionId}/${buildSessionId}/${authToken}`
    ),
    "_blank"
  );
};

export const redirectCodeGenToBrowser = (
  codeSessionId: string,
  authToken: string
) => {
  window.open(
    authParams.redirect_uri?.replace(
      "login",
      `codegenBrowser/${authToken}/${codeSessionId}`
    ),
    "_blank"
  );
};

import React from "react";
import "../Survey.css";
import alertIcon from "@assets/survey/alertIcon.svg";

const Alert = () => {
  return (
    <div className="stepAlert">
      <img src={alertIcon} alt="icon" />
      <p>Please Select one of the options</p>
    </div>
  );
};

export default Alert;

import { CodeGenLoadingScreen } from "@components/CodeGenLoadingScreen";
import { CodeLoadingScreen } from "@components/CodeLoadingScreen";
import { PluginResponse } from "@helper/classes";
import { default as PluginMessageHandler } from "@helper/messageHandler";
import { store } from "@store/index";
import {
  selectAuthToken,
  selectDocumentId,
  selectUser,
  setBuildSessionID,
} from "@store/plugin";
import {
  codeconversionfail,
  codeconversionsuccess,
  trackEvent,
} from "@utils/analytics";
import { buildInitialize, getCode, sendinblueApi } from "@utils/api";
import { fetchError, GLOBAL_ERRORS } from "@utils/errors";
import {
  redirectCodeGenToBrowser,
  redirectToVsCodeTrigger,
} from "@utils/redirects";
import { MessageType } from "@utils/types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const CodeGen = () => {
  const params = useParams();
  const nodeID = params.node_id ? params.node_id : "";

  const documentId = useSelector(selectDocumentId) || "";
  const authToken = useSelector(selectAuthToken) || "";
  const user = useSelector(selectUser);

  const [processing, setProcessingStatus] = useState(true);
  const [title, setTitle] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");
  const [buildID, setBuildID] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken || !documentId || !nodeID) navigate("/error/auth");
  }, [authToken, documentId, nodeID]);

  useEffect(() => {
    PluginMessageHandler.pluginAsyncRequest(MessageType.IMAGE, {
      nodeID: nodeID,
      name: nodeID,
      format: "PNG",
    }).then(gotImage, failedToGetImage);
  }, []);

  const handleViewCode = () => {
    const buttonType = localStorage.getItem("selectedTab") || "Code";
    if (buttonType === "VsCode")
      redirectToVsCodeTrigger(
        sessionID,
        buildID,
        authToken,
        documentId,
        nodeID
      );
    else redirectCodeGenToBrowser(sessionID, authToken);
  };
  const fetchCode = async (image: Uint8Array) => {
    try {
      const response = await getCode(authToken!, documentId!, image, nodeID);

      trackEvent(codeconversionsuccess, {
        document_id: documentId,
        node_id: nodeID,
        request_id: response?.headers["x-request-id"],
      });
      sendinblueApi(user?.email || "", { CODERESULTSUCCESS: true });

      setSessionID(response.data.session_id);
      const buttonType = localStorage.getItem("selectedTab") || "Code";

      if (buttonType === "VsCode") {
        buildInitialize(
          response.data.dependencies,
          response.data.externalFiles,
          user?.user_config?.outputLanguage,
          user?.user_config?.baseTextSize
        ).then(
          (x) => {
            const buildSessionId = x.data["SessionID"];
            console.info("Got SessionID", buildSessionId);
            store.dispatch(setBuildSessionID(buildSessionId));
            setBuildID(buildSessionId);
            redirectToVsCodeTrigger(
              response.data.session_id,
              buildSessionId,
              authToken,
              documentId,
              nodeID
            );
            setTitle("Your code should open in VS Code extension");
            setProcessingStatus(false);
          },
          (err) => {
            console.error("err", err);
          }
        );
      } else {
        redirectCodeGenToBrowser(response.data.session_id, authToken);
        setTitle("Your code should open in a new browser tab");
        setProcessingStatus(false);
      }
    } catch (error) {
      trackEvent(codeconversionfail, {
        document_id: documentId,
        node_id: nodeID,
        request_id: error?.response?.headers["x-request-id"],
      });
      navigate("/error", { state: { errordata: fetchError(error) } });
    }
  };

  const gotImage = (resp: PluginResponse) => {
    if (!resp.data) {
      navigate("/error", { state: { errordata: GLOBAL_ERRORS.unknownError } });
    }
    fetchCode(resp.data.image);
  };

  const failedToGetImage = (resp: PluginResponse) => {
    console.error("Failed to get image", resp);
    navigate("/error", { state: { errordata: GLOBAL_ERRORS.unknownError } });
  };

  if (processing) return <CodeLoadingScreen />;
  else
    return (
      <CodeGenLoadingScreen title={title} handleViewCode={handleViewCode} />
    );
};

export default CodeGen;

import CatLogo from "@assets/cat-logo.png";
import CloseIcon from "@assets/close-icon.png";
import React from "react";

export const ThankyouPage = ({ setOpen }: { setOpen: Function }) => {
  return (
    <div
      className={`bg-white rounded-xl py-[14px] px-[17px] h-[104px] w-[361px] text-center align-middle`}
    >
      <div className="flex flex-row justify-between">
        <div className="flex ml-[20px] text-center items-center font-medium text-[15px] my-[29px]">
          <img src={CatLogo} className="h-[30px] mr-[21px]" />
          Thank you for the feedback
        </div>
        <div onClick={() => setOpen(false)} className="">
          <img src={CloseIcon} className="h-[20px]" />
        </div>
      </div>
    </div>
  );
};

import Footer from "./Footer";
import React from "react";
import PlanCard from "./PlanCard";
import PlanFeatures from "./PlanFeatures";
import { STRIPE_WEBFLOW_PLAN_ID } from "@utils/consts";

export interface PlanDetails {
  id: number;
  title: string;
  subTitle: string;
  time: string;
  isDiscountedPrice: boolean;
  originalPrice: number;
  discount: number;
  price: number;
  offerName: string;
  featuresOffered: string[];
  validityText: string;
  stripePlanId: string;
}

interface Props {
  showTestimonials?: boolean;
  viewMode?: "browser" | "plugin";
}

const items: PlanDetails[] = [
  {
    id: 1,
    title: "CopyCat - Early Bird Offer",
    subTitle:
      "Enjoy lightning-fast development with the flexibility of <b>cancelling anytime</b>. No long-term commitments or hidden fees",
    time: "Month",
    isDiscountedPrice: true,
    originalPrice: 99,
    discount: 50,
    price: 49,
    stripePlanId: STRIPE_WEBFLOW_PLAN_ID,
    offerName: "welcome offer 🥳",
    validityText: "Valid till March 31st 🥳",
    featuresOffered: [
      "Get code instantly in your preferred stack",
      "Flexible code output - Typescript, SCSS/Styled Components, px/em/rem",
      "Bootstrap and MUI component support",
      "IDE with real-time preview to add finishing touches",
    ],
  },
];

const PricingPlans: React.FC<Props> = ({
  viewMode = "browser",
  showTestimonials = true,
}) => {
  return (
    <div>
      {items.map((item) => {
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2">
              {viewMode === "browser" ? (
                <>
                  <PlanFeatures plan={item} />
                  <PlanCard plan={item} viewMode={viewMode} />
                </>
              ) : (
                <>
                  <PlanCard plan={item} viewMode={viewMode} />
                  <PlanFeatures plan={item} />
                </>
              )}
            </div>
            <Footer showTestimonials={showTestimonials} />
          </>
        );
      })}
    </div>
  );
};

export default PricingPlans;

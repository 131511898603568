import { selectDocumentId, selectNodeId } from "@store/plugin";
import Export from "@assets/export.png";
import Maximize from "@assets/maximize.png";
// import Restart from "@assets/restart.png";
import CircularLoader from "@components/CircularLoader";
import { LogoutModal } from "@components/LogoutModal";
import { default as MessageHandler } from "@helper/messageHandler";
import { figmacodeexported, trackEvent } from "@utils/analytics";
import { buildURL } from "@utils/consts";
import { GLOBAL_ERRORS } from "@utils/errors";
import { MessageType } from "@utils/types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { VscSignOut } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { Folder, FolderItem } from "../helpers";
import { FolderMenuItem } from "./FolderMenuItem";

type ExplorerProps = {
  codeFolder: Folder;
  selected: FolderItem | Folder;
  setSelectedItem: (item: FolderItem | Folder) => void;
  showLoader: boolean;
  minimize: boolean;
  setMinimize: Function;
  sessionID: string;
  setOpenSettings: Function;
  openSettings: boolean;
};

export const LeftPanel = ({
  codeFolder,
  selected,
  setSelectedItem,
  showLoader,
  minimize,
  setMinimize,
  sessionID,
  setOpenSettings,
  openSettings,
}: ExplorerProps) => {
  const [isCreatingZip, setIsCreatingZip] = useState(false);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [error, setError] = useState(false);
  const nodeId = useSelector(selectNodeId) || "";
  const documentId = useSelector(selectDocumentId) || "";
  const navigate = useNavigate();
  // const restartPlugin = () => {
  //   navigate("/ready");
  // };

  const handleMinimize = () => {
    setMinimize(true);
    MessageHandler.send(MessageType.RESIZE, { height: 70, width: 248 });
  };

  const handleMaximize = () => {
    setMinimize(false);
    MessageHandler.send(MessageType.RESIZE, { height: 802, width: 1155 });
  };

  return !minimize ? (
    <div className="flex flex-col border-r-2 min-w-[248px]">
      {confirmLogout && <LogoutModal setOpen={setConfirmLogout} />}
      <div className="inline-flex text-[14px] font-bold capitalize justify-between mt-[25px] mb-[12px] mx-[25px] text-[#000000]">
        <div className="inline-flex ">EXPLORER</div>
        {showLoader && (
          <CircularLoader
            width="w-4"
            height="h-4"
            color="text-gray-200"
            fill="fill-gray-600"
          />
        )}
      </div>
      <div className="flex flex-col h-full justify-between ml-[21px]">
        <div
          className="overflow-y-auto"
          onClick={() => {
            setOpenSettings(false);
          }}
        >
          {!showLoader && (
            <FolderMenuItem
              folder={codeFolder}
              setSelectedItem={setSelectedItem}
              selectedItem={selected}
              toggleOpen={true}
            />
          )}
        </div>
        <div className="flex-col mx-auto mb-[20px]">
          {/* <button
            disabled={showLoader}
            onClick={() => {
              setOpenSettings(!openSettings);
            }}
            className={`w-full inline-flex items-center justify-start text-[13px] py-[10px] ${
              showLoader ? "cursor-not-allowed" : "hover:bg-gray-100"
            } ${openSettings ? "bg-gray-100 text-black font-[700]" : null}`}
          >
            <VscGear className="h-[12px] mr-[10px]" />
            Settings
          </button> */}
          <a
            href={buildURL + "/export/" + sessionID}
            onClick={async () => {
              try {
                trackEvent(figmacodeexported, {
                  document_id: documentId,
                  node_ide: nodeId,
                });
                setIsCreatingZip(true);
                // await downloadZip(codeFolder);
                setIsCreatingZip(false);
              } catch (e) {
                navigate("/error", {
                  state: { errordata: GLOBAL_ERRORS.exportError },
                });
              }
            }}
          >
            <button
              disabled={showLoader}
              className={`w-full inline-flex items-center justify-between text-[13px] py-[10px] ${
                showLoader ? "cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <div className="inline-flex items-center mb-2">
                <img src={Export} className="h-[12px] mr-[10px]" />
                Export as zip
              </div>
              <div>
                {isCreatingZip && (
                  <CircularLoader
                    width="w-5 float-right"
                    height="h-5"
                    color="text-gray-200"
                    fill="fill-gray-600"
                  />
                )}
              </div>
            </button>
          </a>
          {/* <button
            disabled={showLoader}
            onClick={() => handleMinimize()}
            className={`w-full inline-flex items-center justify-start text-[13px] py-[10px] ${
              showLoader ? "cursor-not-allowed" : "hover:bg-gray-100"
            }`}
          >
            <img src={Minus} className="h-[12px] mr-[10px]" />
            Minimize Plugin
          </button> */}

          {/* <button
            disabled={showLoader}
            onClick={() => {
              restartPlugin();
            }}
            className={`w-full inline-flex items-center justify-start text-[13px] py-[10px] ${
              showLoader ? "cursor-not-allowed" : "hover:bg-gray-100"
            }`}
          >
            <img src={Restart} className="h-[12px] mr-[10px]" />
            Restart Plugin
          </button> */}
          <button
            disabled={showLoader}
            onClick={() => setConfirmLogout(true)}
            className={`w-full inline-flex items-center justify-start text-[13px] py-[10px] text-red-500 hover:bg-gray-100`}
          >
            <VscSignOut color="#FF0000" className="h-[12px] mr-[10px]" />
            Log out
          </button>
        </div>
      </div>

      {error && (
        <div className="fixed left-5 bottom-10 z-10">
          <div
            id="toast-warning"
            className="flex items-center w-full max-w-xs p-2 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
            role="alert"
          >
            <img src={GLOBAL_ERRORS.exportError.graphic_url} className="w-8" />

            <div>
              <div className="ml-3 text-sm font-normal">
                {GLOBAL_ERRORS.exportError.message}
              </div>
              <div className="ml-3 text-xs font-normal">
                {GLOBAL_ERRORS.exportError.sub_text}
              </div>
            </div>

            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-1 focus:ring-gray-300 p-1 hover:bg-gray-100 inline-flex h-6 w-6 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              data-dismiss-target="#toast-warning"
              aria-label="Close"
              onClick={() => setError(false)}
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <button
      disabled={showLoader}
      onClick={() => handleMaximize()}
      className={`rounded-xl w-full inline-flex border-2 border-black m-[15px] items-center justify-center text-[13px] py-[10px] ${
        showLoader ? "cursor-not-allowed" : "hover:bg-gray-100"
      }`}
    >
      <img src={Maximize} className="h-[12px] mr-[10px]" />
      Maximize Plugin
    </button>
  );
};

import React, { useEffect, useState } from "react";
import { CommentPage } from "./CommentPage";
import { RatingPage } from "./RatingPage";
import { ThankyouPage } from "./ThankyouPage";

// The container with the button to show everywhere
export const FeedbackWrapper = ({ minimize }: { minimize: boolean }) => {
  const [rating, setRating] = useState(1);
  const [open, setOpen] = useState(false);
  const [bounce, setBounce] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setBounce(true);
      setOpen(true);
      setTimeout(() => setBounce(false), 2500);
    }, 2000);
  }, []);
  const [page, setPage] = useState(1);
  return (
    !minimize && (
      <div
        className={`rounded-xl shadow-lg absolute ${
          bounce ? "animate-bounce" : ""
        } ${open ? "block" : "hidden"} z-20 right-[30px] bottom-[45px]`}
      >
        {page === 1 && (
          <RatingPage
            setOpen={setOpen}
            setPage={setPage}
            setRating={setRating}
          />
        )}
        {page === 2 && (
          <CommentPage setOpen={setOpen} setPage={setPage} rating={rating} />
        )}
        {page === 3 && <ThankyouPage setOpen={setOpen} />}
      </div>
    )
  );
};

import unknownError from "@assets/unknownError.png";
import accessError from "@assets/access-error.png";
import { logout } from "@utils/auth";
import { GlobalErrors } from "@utils/types";
import { storeFigmaUrl } from "./figmaUrl";

export const fetchError = (error) => {
  if (error?.response?.data?.detail) {
    const errorValue = error.response.data.detail;
    if (
      errorValue.error_code === "Auth-1" ||
      errorValue.error_code === "Access-2"
    )
      logout();
    if (errorValue.error_code === "FileIn-1") storeFigmaUrl("");
    return errorValue;
  } else return GLOBAL_ERRORS.unknownError;
};

export const GLOBAL_ERRORS: GlobalErrors = {
  unknownError: {
    error_code: "Gen-1",
    message: "Something has gone wrong....",
    sub_text: "We're looking into this right away.",
    graphic_url: unknownError,
    buttons: [
      { text: "Try Again", button_type: "primary", redirect_url: -1 },
      { text: "Go Back", button_type: "primary", redirect_url: "/setup" },
    ],
  },
  selectionError: {
    error_code: "Frame-1",
    message: "Multiple Selections Found",
    sub_text: "Try nesting them under one parent and click on the parent",
    graphic_url: unknownError,
    buttons: [{ text: "Retry", button_type: "primary", redirect_url: -1 }],
  },
  exportError: {
    error_code: "Exp-1",
    message: "Error while exporting",
    sub_text: "This is embarrassing. We're looking into it.",
    graphic_url: unknownError,
    buttons: [
      { text: "Try Again", button_type: "primary", redirect_url: -1 },
      { text: "Go Back", button_type: "primary", redirect_url: "/ready" },
    ],
  },
  incorrectAccount: {
    error_code: "Auth-4",
    message: "Error: Logged in with different account",
    sub_text:
      "This file is open with account name {figma_name} but you logged in with a different account [name: {user_name}, email:{user_email}] ",
    graphic_url: accessError,
    buttons: [
      { text: "Login Again", button_type: "primary", redirect_url: -1 },
    ],
  },
};

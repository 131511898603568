import * as React from "react";
import paymentSuccessStyles from "./PaymentSuccess.module.css";
import gem from "@assets/gem.svg";
import logosslackIcon from "@assets/logosslackIcon.svg";
import logosfigma from "@assets/logosfigma.svg";
import { figmaLink, slackLink } from "@utils/consts";
import { openInNewTab } from "@utils/redirects";

const PaymentSuccess = () => {
  return (
    <div className={paymentSuccessStyles["success-login-browser"]}>
      <div
        className={`${paymentSuccessStyles["rectangle-10180"]} shadow-button-shadow`}
      >
        <div className={paymentSuccessStyles["rectangle-10181"]}>
          <img className={paymentSuccessStyles["gem"]} src={gem} />
          <span className={paymentSuccessStyles["welcome-to-the-premium-club"]}>
            Welcome to Copycat{" "}
            <span className={paymentSuccessStyles["purple-text"]}>Premium</span>{" "}
            Club!
          </span>
        </div>
      </div>
      <span
        className={paymentSuccessStyles["thank-you-your-payment-was-succe"]}
      >
        Thank you,{" "}
      </span>
      <span
        className={paymentSuccessStyles["thank-you-your-payment-was-succe"]}
      >
        Your subscription has been successfully{" "}
        <span className={paymentSuccessStyles["purple-text"]}>activated</span>.
      </span>
      <span
        className={paymentSuccessStyles["you-can-now-get-back-to-figma-an"]}
      >
        Head back to Figma to convert designs to React code seamlessly using
        CopyCat
      </span>
      <div className={paymentSuccessStyles["flex-container"]}>
        <>
          <button
            onClick={() => {
              openInNewTab(figmaLink);
            }}
            className="cursor-pointer shadow-button-shadow px-[25px] justify-center disabled:opacity-50 inline-flex bg-[#FFD803] items-center rounded-xl"
          >
            <img src={logosfigma} className="h-[12px] mr-[10px]" />
            <span className="text-[14px] text-black">Copycat Plugin</span>
          </button>
          <a
            onClick={() => {
              openInNewTab(slackLink);
            }}
            className="cursor-pointer shadow-button-shadow mx-[10px] px-[25px] justify-center inline-flex bg-[#6930CA] items-center rounded-xl"
          >
            <img src={logosslackIcon} className="h-[20px] mr-[10px]" />
            <div className="text-[14px] text-white whitespace-nowrap leading-[10px]">
              Support
            </div>
          </a>
        </>
      </div>
    </div>
  );
};
export default PaymentSuccess;

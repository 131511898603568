import React from "react";
import { useNavigate } from "react-router-dom";

const genCodeUrl =
  "https://storage.googleapis.com/copycatcdn/plugin-assets/videos/code-generation.mp4";

export const CodeGenLoadingScreen = ({
  title,
  handleViewCode,
}: {
  title: string;
  handleViewCode: Function;
}) => {
  const navigate = useNavigate();
  const restartPlugin = () => {
    localStorage.setItem("selectedTab", "Code");
    navigate("/ready");
  };

  return (
    <div className="flex flex-col  h-screen bg-[#ffffff]">
      <div className="flex flex-col rounded-xl justify-center mx-[60px] h-full items-center">
        <video
          className="mb-[10px] mx-auto h-[180px]"
          loop
          src={genCodeUrl}
          autoPlay
          muted
        />
        <h2 className="text-[22px] font-bold text-center mb-[10px]">{title}</h2>
        <div className="text-[16px] text-center my-[4px]">
          Can't see it? Please disable your popup blocker.
        </div>
      </div>
      <div className="flex w-full gap-[20px] bottom-0 p-[30px]">
        <button
          className="cursor-pointer shadow-button-shadow justify-center border-black border-[1px] inline-flex bg-transparent disabled:opacity-50 items-center rounded-lg py-[12px] w-full"
          onClick={() => handleViewCode()}
        >
          View Code
        </button>
        <button
          className="cursor-pointer  shadow-button-shadow justify-center inline-flex bg-[#FFD803] disabled:opacity-50 items-center rounded-lg py-[12px] w-full"
          onClick={() => restartPlugin()}
        >
          Start again
        </button>
      </div>
    </div>
  );
};

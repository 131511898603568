import AllowPopup from "@assets/allow-popup.png";
import CircularLoader from "@components/CircularLoader";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import BigCatLogo from "url:/@assets/cat_anim.gif";

const VsCodeTrigger = () => {
  const params = useParams();
  const sessionId = params.sessionId;
  const authToken = params.authToken;
  const buildSessionId = params.buildSessionId;
  useEffect(() => {
    window.open(
      `vscode://copycatdev.copycat-figma-to-react/generatingcode?sessionId=${sessionId}&buildSessionId=${buildSessionId}&authToken=${authToken}`,
      "_blank"
    );
  });

  return (
    <div className="mx-auto flex flex-col items-center w-screen h-screen bg-[#FFD803] justify-center">
      <div className="inline-flex justify-center items-center w-full">
        <div className="flex-col w-2/5 text-center border-r-2 border-black">
          <img src={BigCatLogo} width={312} height={271} className="mx-auto" />
          <span className=" mt-[37px] inline-flex text-[40px] items-center text-center text-black font-[700] ">
            <CircularLoader
              width={"w-[46px]"}
              height={"h-[46px]"}
              fill={"fill-[#FFD803]"}
              color={"text-[#0B8AFF]"}
            />
            Redirecting to Vs Code
          </span>
        </div>
        <div className="flex-col justify-between text-center w-2/5">
          <img src={AllowPopup} className="mx-auto mb-[50px]" />
          <span className="mt-[50px] text-[30px] items-center text-center">
            Please Click on Allow Pop-up to continue
          </span>
        </div>
      </div>
    </div>
  );
};
export default VsCodeTrigger;

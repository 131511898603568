import Minus from "@assets/minus.png";
import Plus from "@assets/plus.png";
import CircularLoader from "@components/CircularLoader";
import {
  selectBuildSessionID,
  selectDocumentId,
  selectNodeId,
} from "@store/plugin";
import {
  codeResultPreview,
  codeResultPreviewError,
  trackEvent,
} from "@utils/analytics";
import { renderFile } from "@utils/api";
import { previewURL } from "@utils/consts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FolderItem } from "../helpers";
import "./CodePreview.css";

interface RenderProps {
  file: FolderItem;
}

export const RenderCode = ({ file }: RenderProps) => {
  const sessionID = useSelector(selectBuildSessionID);
  const documentId = useSelector(selectDocumentId) || "";
  const nodeId = useSelector(selectNodeId) || "";
  const [error, setError] = useState<boolean>(false);
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    if (file != null && sessionID) {
      setProcessing(true);
      const componentName = file.name.split(".")[0];
      renderFile(
        sessionID,
        file.getPath(),
        componentName,
        file.filetype,
        file.additionalImports
      ).then(
        (_) => {
          trackEvent(codeResultPreview, {
            document_id: documentId,
            node_id: nodeId,
          });
          setProcessing(false);
        },
        (err) => {
          trackEvent(codeResultPreviewError, {
            documentId,
            nodeId,
            error: err.message,
          });
          setError(true);
          console.log(err);
        }
      );
    }
  }, [file, sessionID]);

  if (error) return <div>Error</div>;
  return (
    <div
      className={`bg-[#F5F5F5] flex ${
        processing && "items-center"
      } justify-center gap-4 codetab-height`}
    >
      {processing ? (
        <div className="flex-col text-center">
          Rendering
          <br />
          <br />
          <CircularLoader
            width={"w-[46px]"}
            height={"h-[46px]"}
            fill={"fill-[#D4DBE3]"}
            color={"text-[#0B8AFF]"}
          />
        </div>
      ) : (
        <PreviewIframe sessionID={sessionID!} />
      )}{" "}
    </div>
  );
};

interface PreviewIframeProps {
  sessionID: string;
}
export const PreviewIframe = ({ sessionID }: PreviewIframeProps) => {
  let url = previewURL!.replace("[SESSIONID]", sessionID!);
  const [scalePercentage, setScalePercentage] = useState(50);

  function zoomIn() {
    setScalePercentage(scalePercentage + 5);
  }
  function zoomOut() {
    setScalePercentage(Math.max(scalePercentage - 5, 5));
  }

  return (
    <div className="previewParent">
      <object
        data={url}
        type="text/html"
        className="previewIframe"
        style={
          {
            "--scale-width-height": 10000 / scalePercentage + "%",
            "--zoom-scale": scalePercentage / 100,
          } as React.CSSProperties
        }
      >
        Failed to Render
      </object>
      <div className=" absolute rounded-lg right-0 bottom-[10rem] mx-[20px] bg-white flex-col shadow-lg ">
        <button className="flex text-black bg-white" onClick={zoomIn}>
          <img src={Plus} className="h-[15px] m-[5px]" />
        </button>
        <button
          className="flex text-black bg-white border-t-2"
          onClick={zoomOut}
        >
          <img src={Minus} className="h-[15px] m-[5px] " />
        </button>
      </div>
    </div>
  );
};

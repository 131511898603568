import { selectUser } from "@store/plugin";
import * as React from "react";
import { useSelector } from "react-redux";
import CatLogo from "@assets/cat-logo.png";
export const Navbar = () => {
  const user = useSelector(selectUser);
  return (
    <div className="h-[64px] items-center px-[30px] border-b-[1px] inline-flex justify-between w-full">
      <div className="inline-flex items-center">
        <img src={CatLogo} className="h-[28px] " />
        <span className="text-[14px] px-[8px] font-[700]">CopyCat</span>
      </div>

      <div className="float-right inline-flex items-center">
        <img className="h-[28px] rounded-full" src={user?.img_url} />
        <span className="text-[14px] px-[8px]">{user?.handle}</span>
      </div>
    </div>
  );
};

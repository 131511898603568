import { codeResultStyles, trackEvent } from "@utils/analytics";
import React from "react";
import { CodePreview } from "./CodePreview";
import { Folder, FolderItem } from "../helpers";
import Settings from "../../Settings";
import { SettingsWidget } from "@components/SettingsWidget/Widget";

type PreviewProps = {
  selectedFile: Folder | FolderItem;
  assetsLoading: boolean;
  setOpenSettings: Function;
  openSettings: boolean;
  setUpdatingSettings: Function;
  updateSelectedFile?: (data: FolderItem) => void;
};
const filetype = new Map([
  ["png", "image"],
  ["svg", "image"],
  ["ts", "code"],
  ["tsx", "code"],
  ["js", "code"],
  ["jsx", "code"],
  ["code", "code"],
  ["css", "code"],
  ["scss", "code"],
  ["sass", "code"],
]);
export const RightPanel = ({
  selectedFile: file,
  assetsLoading,
  setOpenSettings,
  setUpdatingSettings,
  openSettings,
}: PreviewProps) => {
  if (openSettings) {
    return (
      <Settings
        setOpenSettings={setOpenSettings}
        setUpdatingSettings={setUpdatingSettings}
      />
    );
  } else {
    if (file && file instanceof FolderItem) {
      if (filetype.get(file.filetype) == "code") {
        if (file.filetype === "css") trackEvent(codeResultStyles);
        return <CodePreview file={file} assetsLoading={assetsLoading} />;
      } else if (filetype.get(file.filetype) == "image")
        return <ImagePreview url={file.url} />;
      else return <ErrorPreview />;
    }
  }

  return (
    <div className="grow relative">
      <div className=" w-full h-full bg-gray-100 text-xl flex items-center justify-center ">
        Select file to view
      </div>
      <SettingsWidget />
    </div>
  );
};

const ImagePreview = ({ url }: { url: string | undefined }) => {
  return (
    <div className=" w-full h-full bg-gray-100 flex items-center justify-center">
      <img src={url || "https://via.placeholder.com/350x272"} />
    </div>
  );
};

const ErrorPreview = () => {
  return (
    <div className="flex h-screen p-4 flex-col mx-24 justify-center items-center">
      <h1 className="mt-5 px-10  text-3xl font-semibold text-center text-black py-2">
        Error in preview generation
      </h1>
      <div className="mt-5 px-10 text-sm font-normal text-center text-gray-800 ">
        This is embarrassing. We're looking into it.
      </div>
    </div>
  );
};

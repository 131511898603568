import React, { useEffect, useState } from "react";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";
import { Folder, FolderItem } from "../helpers";
import { FileMenuItem } from "./FileMenuItem";

const explorerStyle =
  " text-[13px] inline-flex items-center min-w-[150px] p-[3px] leading-[13px] ";
const hoverClasses = " hover:bg-gray-100 cursor-pointer font-[700]";
const activeClasses = "bg-gray-100 font-[700] ";
const toggleClasses = "";

type FolderProps = {
  folder: Folder;
  setSelectedItem: (file: FolderItem | Folder) => void;
  selectedItem: Folder | FolderItem;
  toggleOpen?: boolean;
};

export const FolderMenuItem = ({
  folder,
  selectedItem,
  setSelectedItem,
  toggleOpen,
}: FolderProps) => {
  const [isFolderSelected, setSelectedState] = useState(false);
  const [toggle, setToggle] = useState<boolean>(toggleOpen || false);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      setSelectedState(selectedItem.id === folder.id);
    }
  }, [selectedItem]);

  return (
    <div className="text-[#606060]">
      <div
        onClick={() => {
          setToggle(!toggle);
          setSelectedItem(folder);
        }}
        // key={folder.id}
        className={`${explorerStyle} ${
          isFolderSelected ? activeClasses : "font-[500]"
        } ${toggle ? toggleClasses : ""} ${hoverClasses}`}
      >
        <span className="mr-[3px]">
          {toggle ? (
            <VscChevronDown width={16} />
          ) : (
            <VscChevronRight width={16} />
          )}
        </span>
        <span>{folder.name}</span>
      </div>

      {/* Show Children if toggled */}
      {toggle && (
        <div className="ml-[6px] flex flex-col">
          {folder.contents
            .sort(function (a, b) {
              if (a instanceof Folder && b instanceof FolderItem) return -1;
              if (a instanceof FolderItem && b instanceof Folder) return 1;
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })
            .map((item) => {
              if (item instanceof Folder) {
                return (
                  <FolderMenuItem
                    folder={item}
                    key={item.id}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                );
              } else {
                return (
                  <FileMenuItem
                    file={item}
                    key={item.id}
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                  />
                );
              }
            })}
        </div>
      )}
    </div>
  );
};

import {
  languageOptions,
  stylesheetOptions,
  unitsOptions,
  libraryOptions,
} from "@utils/settingsMetadata";
import React, { useEffect, useRef, useState } from "react";

import arrowBottom from "@assets/arrowBottom.svg";
import arrowTop from "@assets/arrowTop.svg";
import bootstrapLogo from "@assets/bootstrap.svg";
import helpIcon from "@assets/talk-to-us.png";
import materialDesignLogo from "@assets/material-design.png";
import tailwindLogo from "@assets/tailwind.png";

import CircularLoader from "@components/CircularLoader";
import {
  selectAuthToken,
  selectCodeSessionID,
  selectDocumentId,
  selectNodeId,
  selectUser,
} from "@store/plugin";
import { codeOutputSettingsSubmit, trackEvent } from "@utils/analytics";
import { getUserInfo, regenerateCodegen, saveUserConfig } from "@utils/api";
import { storeUser } from "@utils/auth";
import { fetchError } from "@utils/errors";
import { chatWidgetLink } from "@utils/consts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "./Dropdown";
import { Options } from "./Options";
import {
  MUI_LIBRARY_COMPONENT_ID,
  BOOTSTRAP_LIBRARY_COMPONENT_ID,
} from "@utils/consts";

export const SettingsWidget = () => {
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken) || "";
  const sessionId = useSelector(selectCodeSessionID) || "";
  const documentId = useSelector(selectDocumentId) || "";
  const nodeId = useSelector(selectNodeId) || "";

  const [baseTextSize, setBaseTextSize] = useState(
    user?.user_config.baseTextSize || 14
  );
  const textSizes = [];
  for (var i = 6; i < 81; i++) {
    textSizes.push({ value: i, label: i + "px" });
  }

  const [basePaddingUnit, setBasePaddingUnit] = useState(
    user?.user_config.paddingUnit || "px"
  );
  const [UILibrary, setUILibrary] = useState<any>(
    user?.user_config.library_component_id || null
  );

  const [baseMarginUnit, setBaseMarginUnit] = useState(
    user?.user_config.marginUnit || "px"
  );
  const [languageSelector, setLanguageSelector] = useState(
    languageOptions.find(
      (option) => option.value === user?.user_config.outputLanguage
    )?.value || languageOptions[0].value
  );
  const [styleSheetSelector, setStyleSheetSelector] = useState(
    stylesheetOptions.find(
      (option) => option.value === user?.user_config.StyleSheetConfig
    )?.value || stylesheetOptions[0].value
  );
  const [showLoader, setShowLoader] = useState(false);
  const [didWidgetChange, setDidWidgetChange] = useState(true);
  const [showWidget, setShowWidget] = useState(!user?.completed_first_codegen);

  const wrapperRef = useRef(null);
  const clickInWidget = useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (!clickInWidget) setShowWidget(false);
  }, [clickInWidget]);

  const discardChanges = () => {
    setBaseMarginUnit(user?.user_config.marginUnit || "px");
    setBasePaddingUnit(user?.user_config.paddingUnit || "px");
    setBaseTextSize(user?.user_config.baseTextSize || 14);
    setStyleSheetSelector(
      stylesheetOptions.find(
        (option) => option.value === user?.user_config.StyleSheetConfig
      )?.value || stylesheetOptions[0].value
    );
    setLanguageSelector(
      languageOptions.find(
        (option) => option.value === user?.user_config.outputLanguage
      )?.value || languageOptions[0].value
    );
    setUILibrary(user?.user_config.library_component_id || null);
  };
  const handleSubmit = async () => {
    setShowLoader(true);
    const data = {
      baseTextSize: baseTextSize,
      paddingUnit: basePaddingUnit,
      marginUnit: baseMarginUnit,
      outputLanguage: languageSelector,
      StyleSheetConfig: styleSheetSelector,
      library_component_id: UILibrary,
    };

    // Getting the details of the selected library component to send in the event data
    const selected_component_libary = libraryOptions.find(
      (option) => option.value == UILibrary
    );
    // TODO: THIS NEEDS TO BE HANDLED BETTER AS THE SAME DATA IS USED IN MULTIPLE PLACES AND IS DEFINED TWICE
    const eventData = {
      baseTextSize: baseTextSize,
      paddingUnit: basePaddingUnit,
      marginUnit: baseMarginUnit,
      outputLanguage: languageSelector,
      StyleSheetConfig: styleSheetSelector,
      library_component_id: selected_component_libary.value,
      library_component_name: selected_component_libary.label,
      document_id: documentId,
      node_id: nodeId,
    };
    try {
      trackEvent(codeOutputSettingsSubmit, eventData, "codeoutput");
      const response = await saveUserConfig(authToken, data);

      if (response.data === "ok") {
        const userResponse = await getUserInfo(authToken as string);
        const user = userResponse.data;
        if (user && user.id) {
          storeUser(user);
          const response = await regenerateCodegen(authToken, sessionId);
          navigate(`/codegenBrowser/${authToken}/${response.data.session_id}`);
        }
      } else {
        setShowLoader(false);
      }
    } catch (e) {
      navigate("/error", { state: { errordata: fetchError(e) } });
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={`transition-all flex flex-col duration-500 shadow-[0px_-2px_20px_rgb(0,0,0,0.2)] bg-white w-full absolute z-10 ${
        showWidget ? "bottom-[60px]" : "bottom-[-130px]"
      }`}
    >
      <div className="w-full flex justify-between py-[18px] px-[25]">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            if (!showWidget)
              trackEvent("codeoutputsettings-toggle", {
                state: !showWidget,
              });
            setShowWidget(!showWidget);
          }}
        >
          <span className="h-fit text-[14px] font-bold">Customize code</span>
          <img
            className="w-[12px] h-[8px] ml-[1rem]"
            src={showWidget ? arrowBottom : arrowTop}
          />
        </div>
        {/* <a href={chatWidgetLink} target="_blank" referrerPolicy="no-referrer">
          <div className="flex items-center space-x-2 justify-between">
            <div className="text-sm">Talk to us</div>
            <img src={helpIcon} alt="" />
          </div>
        </a> */}
      </div>

      <div className="px-[25px] pb-[25px] pt-[10px] flex items-center">
        <span className="mr-[20px] text-[12px] font-[500]">UI Library:</span>
        <input
          type="radio"
          id="none"
          name="UILibrary"
          value="None"
          onChange={() => {
            setUILibrary(null);
            setDidWidgetChange(false);
          }}
          checked={!UILibrary}
        />
        <label
          className="mr-[30px] cursor-pointer ml-[7px] hover:text-[#000000] text-[#606060] flex items-center"
          htmlFor="none"
        >
          None
        </label>
        <input
          type="radio"
          id="materialUI"
          name="UILibrary"
          value={MUI_LIBRARY_COMPONENT_ID}
          onChange={() => {
            setUILibrary(MUI_LIBRARY_COMPONENT_ID);
            setDidWidgetChange(false);
          }}
          checked={UILibrary == MUI_LIBRARY_COMPONENT_ID}
        />
        <label
          className="mr-[30px] cursor-pointer ml-[7px] hover:text-[#000000] text-[#606060] flex items-center"
          htmlFor="materialUI"
        >
          <img
            src={materialDesignLogo}
            className="mr-[7px] w-[22px] h-[15px] "
          />
          Material UI
        </label>
        <input
          type="radio"
          id="bootstrap"
          name="UILibrary"
          value={BOOTSTRAP_LIBRARY_COMPONENT_ID}
          onChange={() => {
            setUILibrary(BOOTSTRAP_LIBRARY_COMPONENT_ID);
            setDidWidgetChange(false);
          }}
          checked={UILibrary == BOOTSTRAP_LIBRARY_COMPONENT_ID}
        />
        <label
          className="mr-[30px] cursor-pointer ml-[7px] hover:text-[#000000] text-[#606060] flex items-center"
          htmlFor="bootstrap"
        >
          <img src={bootstrapLogo} className="mr-[7px] w-[22px] h-[15px] " />
          Bootstrap
        </label>
        <input
          type="radio"
          disabled
          id="tailwind"
          name="UILibrary"
          value="Tailwind"
          onChange={() => {
            setUILibrary(2);
            setDidWidgetChange(false);
          }}
          checked={UILibrary === 2}
        />
        <label
          className="mr-[30px] cursor-not-allowed ml-[7px] text-[#606060] flex items-center line-through"
          htmlFor="tailwind"
        >
          <img src={tailwindLogo} className="mr-[7px] w-[22px] h-[15px] " />
          Tailwind
        </label>
      </div>

      <div className="flex w-full">
        <Dropdown
          label={"Text Size"}
          setSize={(value: any) => {
            setBaseTextSize(value);
            setDidWidgetChange(false);
          }}
          size={baseTextSize.toString()}
          options={textSizes}
        />

        <Dropdown
          label={"Padding"}
          setSize={(value: any) => {
            setBasePaddingUnit(value);
            setDidWidgetChange(false);
          }}
          size={basePaddingUnit}
          options={unitsOptions[1].options}
        />

        <Dropdown
          label={"Margin"}
          setSize={(value: any) => {
            setBaseMarginUnit(value);
            setDidWidgetChange(false);
          }}
          size={baseMarginUnit}
          options={unitsOptions[0].options}
        />
        <Options
          label={"Output"}
          selected={languageSelector || "jsx"}
          options={languageOptions}
          setOption={(value: any) => {
            setLanguageSelector(value);
            setDidWidgetChange(false);
          }}
        />

        <Dropdown
          label={"Styling"}
          setSize={(value: any) => {
            setStyleSheetSelector(value);
            setDidWidgetChange(false);
          }}
          size={styleSheetSelector || "CssModules"}
          options={stylesheetOptions}
        />
      </div>
      <div className="px-[25px] pb-[20px] pt-[30px] w-full flex items-center">
        {showLoader ? (
          <CircularLoader
            width="w-4 mr-0"
            height="h-4"
            color="text-gray-200"
            fill="fill-gray-600"
          />
        ) : (
          <>
            <button
              onClick={() => {
                discardChanges();
                setDidWidgetChange(true);
              }}
              disabled={didWidgetChange}
              className={` shadow-button-shadow px-[25px] justify-center disabled:opacity-50 inline-flex ${
                didWidgetChange
                  ? "bg-[#dedede] cursor-not-allowed"
                  : "bg-[#ffffff] cursor-pointer"
              } items-center rounded-xl py-[10px] w-fit border-solid border-[1px] border-[#000000] mr-[15px]`}
            >
              <span className="text-[14px] text-black">Discard</span>
            </button>
            <button
              onClick={() => {
                handleSubmit();
              }}
              disabled={didWidgetChange}
              className={`shadow-button-shadow px-[25px] justify-center disabled:opacity-50 inline-flex ${
                didWidgetChange
                  ? "bg-[#e4cf5c] cursor-not-allowed"
                  : "bg-[#FFD803] cursor-pointer"
              } items-center rounded-xl py-[10px] w-fit border-solid border-[1px] border-[#000000]`}
            >
              <span className="text-[14px] text-black">Save</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

function useOutsideAlerter(ref) {
  const [clickInWidget, setClickInWidget] = useState(true);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickInWidget(false);
      } else setClickInWidget(true);
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return clickInWidget;
}

import LoadingScreen from "@components/LoadingScreen";
import { generateStripeSessionLink, getSubscriptionStatus } from "@utils/api";
import { fetchError } from "@utils/errors";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CatWithBack from "@assets/cat-withback.svg";
import Check from "@assets/circle-check-fill.png";
import { useSelector } from "react-redux";
import { selectAuthToken, selectUser } from "@store/plugin";
import { WebflowPlan, WebflowPlanType } from "./WebflowPlan";
import { SignupInfo, StripeSessionInfo } from "@utils/types";
import catpattern from "@assets/cat-pattern.png";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
const plan: WebflowPlanType = {
  info: "Risk free trial for CopyCat Figma -> Webflow Conversion.",
  detail: "Easy Self Cancellation",
  price: 50,
  name: "Monthly Plan",
  properties: [
    ["Unlimited generation on unlimited websites", "green"],
    ["Slack+Email priority support", "green"],
    ["Auto conversion of Input and Button types from designs", "green"],
    ["Generate flex layout without having Autolayout in frames", "green"],
    ["Tons of improvements/new features weekly", "green"],
  ],
  cta: "",
} as WebflowPlanType;

const WebflowPricing = ({ subscribed }: { subscribed: Boolean }) => {
  const authToken = useSelector(selectAuthToken) as string;
  const [myPlan, setMyPlan] = useState<WebflowPlanType>(plan);
  const user = useSelector(selectUser);
  let generateStripeSession = () => {
    let data: StripeSessionInfo = {
      signupInfo: { name: user?.handle, email: user?.email } as SignupInfo,
      planId: "webflow",
      signupFrom: "website",
      state: "cc-webflow-signup-website",
      successUrl: window.location.origin + "/webflow/subscription/success",
      cancelUrl: window.location.origin + "/webflow/subscription/fail",
    };
    generateStripeSessionLink(data, authToken)
      .then((resp: any) => {
        myPlan.cta = resp.data.redirectURL;
      })
      // TODO: ADD error handling. I'm(tosh) loginnot familiar with the  error formar we have now
      .catch((error: any) => {});
  };

  useEffect(() => {
    generateStripeSession();
  }, [subscribed === false]);

  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <h1 className="text-[48px] font-bold">Select a plan to get started</h1>
      <WebflowPlan plan={myPlan} />
    </div>
  );
};

const LoginSucesss = () => {
  return (
    <div className="flex-col justify-center items-center">
      <img src={CatWithBack} className="mx-auto  h-1/2" />
      <div className="flex justify-center">
        <h1 className=" mt-[37px] inline-flex text-[53px] items-center text-center text-black font-[700] ">
          <img src={Check} className="mr-[18px] h-14" />
          Login Successful!
        </h1>
      </div>
      <div className="mx-auto mt-[50px] text-[30px] font-regular text-center text-black">
        Open the CopyCat Chrome plugin and start using CopyCat!{" "}
        <p>
          <a
            className="underline link"
            target="_blank"
            href="https://www.notion.so/copycatdev/Chrome-Extension-Documentation-e568bf8a1c7d476ca656ebdaf9d07fc7"
          >
            Click here for more instructions
          </a>
        </p>
      </div>
      <br />
    </div>
  );
};
const WebflowLogin = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const authToken = useSelector(selectAuthToken);
  const [loadingMessage, setLoadingMessage] = useState(
    "Login Successfull! Processing..."
  );
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(true);

  useEffect(() => {
    if (authToken) {
      getSubscriptionStatus(authToken)
        .then((resp: any) => {
          if (resp.data.active) {
            setSubscribed(true);
          }
          setIsAuthenticating(false);
        })
        .catch((error: any) => {
          navigate("/error", { state: { errordata: fetchError(error) } });
        });
    } else {
      navigate("/error", {
        state: { errordata: fetchError("Webflow <> Figma Not Authenticated") },
      });
    }
  }, []);

  return isAuthenticating ? (
    <LoadingScreen message={loadingMessage} />
  ) : (
    <div
      style={{ backgroundImage: `url(${catpattern})` }}
      className="mx-auto flex flex-col items-center w-screen h-screen justify-center"
    >
      {subscribed ? (
        <LoginSucesss />
      ) : (
        <div>
          <WebflowPricing subscribed={subscribed} />
        </div>
      )}
    </div>
  );
};

export default WebflowLogin;

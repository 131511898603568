import quote from "@assets/quote.svg";
import slackLogo from "@assets/slackLogo.svg";
import trail from "@assets/trail.svg";
import { trackEvent, trialExpireSeen } from "@utils/analytics";
import React from "react";

import { useNavigate } from "react-router-dom";

const dummyTestimonials = [
  {
    name: "Rahul Chacko",
    job: "Product Lead @ Qyuki Digital Media",
    avatar:
      "https://uploads-ssl.webflow.com/602a49b4c90708f6641a192e/630999aa78b484850c6a5e65_rahul%20chacko.jpeg",
    message:
      "My front-end team had to scale our existing product rapidly, and we were short-staffed. CopyCat helped us scale quickly by helping us increase capacity without adding new developers.",
  },
  {
    name: "Brahmnoor Chawla",
    job: "Software Engineer @ Google",
    avatar:
      "https://uploads-ssl.webflow.com/602a49b4c90708f6641a192e/62907b66671ae31ac24ed3a0_1581826680098.jpg",
    message:
      "CopyCat helped us build a production-grade web app and ship it quickly.",
  },
  {
    name: "Ilango Rajagopal",
    job: "Software Developer, Easy Auth",
    avatar:
      "https://uploads-ssl.webflow.com/602a49b4c90708f6641a192e/62907b82c4d0751d28e918f7_1554957388524.jpg",
    message:
      "CopyCat takes care of all the boiler-plate code files, assets, and components that save 20% of my time every sprint.",
  },
  {
    name: "Matthias Coutin",
    job: "Development Engineer @ Logicells",
    avatar: "https://storage.googleapis.com/copycatcdn/3s7mgaqp_400x400.jpeg",
    message:
      "CopyCat is very promising in our development workflow. I have come to the conclusion that it does 70% of our work.",
  },
];

interface propsType {
  withPlans: boolean;
  testimonial: boolean;
}
function Trial({ withPlans, testimonial }: propsType) {
  trackEvent(trialExpireSeen);
  const selectedTestimonial =
    dummyTestimonials[Math.floor(Math.random() * dummyTestimonials.length)];
  const navigate = useNavigate();
  return (
    <div className="h-screen w-full">
      <div className="flex-col px-[30px] py-[10px]">
        <div
          className="text-[14px] underline text-[#0075FF] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          Go back
        </div>
      </div>

      <div
        className={`flex-col px-[30px] w-full justify-center flex items-center ${
          !testimonial ? "my-16" : ""
        }`}
      >
        <img src={trail} alt="" className="mb-4 w-28 h-17" />

        <span className="font-bold text-2xl mb-4">Your Trial Has Ended</span>
        <p className="text-base text-[#555555] text-center">
          But we are on beta so join our Slack Community and extend your Premium
          Membership by a month
        </p>

        {withPlans ? (
          <a
            href="https://join.slack.com/t/copycat-community/shared_invite/zt-1fn7dvj8w-BSygh6gIcf7h4cBDhVR8KQ"
            target="_blank"
            className={`mx-4 flex-col items-center flex w-full justify-center rounded-md  bg-[#1F2732] px-4 py-2 text-base font-medium text-black shadow-sm hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
              testimonial ? "mt-10" : "mt-14"
            } mb-4`}
          >
            <span className=" text-xs text-[#ffffff]">See all plans</span>
            <span className=" text-xs text-[#ffffff]">
              (Start from $8 per month)
            </span>
          </a>
        ) : (
          <a
            href="https://join.slack.com/t/copycat-community/shared_invite/zt-1fn7dvj8w-BSygh6gIcf7h4cBDhVR8KQ"
            target="_blank"
            className={`mx-4 inline-flex w-full justify-center rounded-md  bg-[#FFD803] px-4 py-2 text-base font-medium text-black shadow-sm hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
              testimonial ? "mt-10" : "mt-14"
            } mb-4`}
          >
            <img src={slackLogo} alt="" className="mr-2" />
            <span>Join Now</span>
          </a>
        )}

        {testimonial && (
          <div className="px-[30px] py-[10px] bg-[#ffffff] w-full rounded-md border border-gray-300 shadow-sm flex flex-col justify-center items-center">
            <img src={quote} alt="" className="w-6" />
            <p
              title={selectedTestimonial.message}
              className="text-base w-full text-[#555555] text-center"
            >
              {selectedTestimonial.message}
            </p>
            <div className="w-full justify-center flex flex-row items-center pt-[10px]">
              <img
                src={selectedTestimonial.avatar}
                alt=""
                className="mr-2 rounded-full w-[42px]"
              />
              <div>
                <span className="text-[16px] font-[500] flex flex-col justify-center items-start">
                  {selectedTestimonial.name}
                </span>
                <span className="text-[12px] text-[#555555]">
                  {selectedTestimonial.job}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="fixed flex w-full gap-[20px] bottom-0 p-[30px] text-[14px] flex-row justify-center underline">
        <a href="https://www.copycat.dev/" target="_blank">
          Need help?
        </a>
      </div>
    </div>
  );
}

export default Trial;

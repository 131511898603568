.group-1314 {
  color: #000;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  font-family: Roboto, Arial;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 23px;
  display: flex;
  background-color: var(--cwh);
  border-radius: var(--borderRadius5);
  box-shadow: var(--boxShadow);
  padding: 1.5rem 1rem;
}

.rectangle-578 {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: space-evenly;
  padding-right: 3px;
  display: flex;
}

.flex-container {
  height: 55%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 4px;
  font-weight: 600;
  display: flex;
}

.bxsquote-alt-left {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.ellipse-41 {
  width: 47px;
  height: 47px;
  object-fit: cover;
  background-position: center;
  background-size: cover;

  border-radius: 50%;
}

.flex-container-1 {
  height: 30%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  /* margin-top: 10px; */
  font-size: 0.875rem;
  display: flex;
}

.anna-smith {
  font-size: 1.125rem;
}

.tech-lead-at-oracle {
  color: #666;
  line-height: 18px;
}

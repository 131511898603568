import React, { useState } from "react";
import { stepsPropsType } from "..";
import "../Survey.css";

const items = [
  {
    id: 1,
    title: "Get the CSS/SCSS styling quickly",
  },
  {
    id: 2,
    title: "Get the component boilerplate",
  },
  {
    id: 3,
    title: "Get the whole page's layout",
  },
  {
    id: 4,
    title: "Quickly export assets",
  },
  {
    id: 5,
    title: "All of the above",
  },
  {
    id: 6,
    title: "Others",
  },
];
const SurveyStepThree = ({
  formData,
  setFormData,
  setActiveAlert,
  setOthersSelected,
}: stepsPropsType) => {
  const [isActive, setIsActive] = useState(false);

  const setData = (title: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        intention: title,
      };
    });
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        intention: event.target.value,
      };
    });
  };

  return (
    <div className="stepBody stepthree">
      <div>
        <h1>3. Primary Intention</h1>
        <div className="subTitle">
          <p>My primary intention for using Copycat is to </p>
          <hr />
        </div>
      </div>

      <ul>
        {items.map((item, index) => {
          return (
            <li
              key={item.id}
              onClick={() => {
                item.title === "Others"
                  ? setIsActive(true)
                  : setIsActive(false);
              }}
            >
              <label className="labelCard">
                <input
                  type="radio"
                  name="radio"
                  onChange={() => {
                    if (item.title !== "Others") {
                      setData(item.title);
                      setOthersSelected!((prev: any) => ({
                        ...prev,
                        thirdPage: false,
                      }));
                    } else {
                      setData("");
                      setOthersSelected!((prev: any) => ({
                        ...prev,
                        thirdPage: true,
                      }));
                    }
                    setActiveAlert(false);
                  }}
                  defaultChecked={formData.intention === item.title}
                  value={""}
                />
                <span className="checkmark"></span>
                <div className="stepContent">
                  <h4>{item.title}</h4>
                </div>
              </label>
            </li>
          );
        })}
      </ul>

      {isActive && (
        <textarea
          name="stepComment"
          placeholder="Can you tell us more ..."
          className="stepComment"
          value={formData.intention === "Others" ? "" : formData.intention}
          onChange={handleMessageChange}
          required
        />
      )}
    </div>
  );
};

export default SurveyStepThree;

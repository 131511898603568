import CircularLoader from "@components/CircularLoader";
import React from "react";
import { useNavigate } from "react-router-dom";

const genCodeUrl =
  "https://storage.googleapis.com/copycatcdn/plugin-assets/videos/code-generation.mp4";

export const CodeLoadingScreen = ({ message }: { message?: string }) => {
  const navigate = useNavigate();
  const restartPlugin = () => {
    localStorage.setItem("selectedTab", "Code");
    navigate("/ready");
  };

  return (
    <div className="flex flex-col justify-evenly h-screen bg-[#ffffff]">
      <div className="rounded-xl  justify-center mx-[20px] mt-[60px] mb-[60px] p-[50px]">
        <video
          className="mb-[30px] mx-auto h-[200px]"
          loop
          src={genCodeUrl}
          autoPlay
          muted
        />
        <h2 className="text-[24px] font-bold text-center mb-[10px]">
          {message || "Generating React Code"}
        </h2>
        <div className="text-center mb-[30px]">
          {!message && (
            <CircularLoader
              width={"w-[46px]"}
              height={"h-[46px]"}
              fill={"fill-[#ffffff]"}
              color={"text-[#0B8AFF]"}
            />
          )}
        </div>
        <div className="text-center cursor-pointer hover:text-semibold">
          <button className="text-stone-400" onClick={() => restartPlugin()}>
            Restart Plugin
          </button>
        </div>
      </div>
    </div>
  );
};

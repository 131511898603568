import React from "react";
import { SettingCard } from "./SettingsCard";

export const Dropdown = ({
  label,
  size,
  setSize,
  options,
}: {
  label: string;
  size: string;
  setSize: any;
  options: any;
}) => {
  return (
    <SettingCard label={label}>
      <select
        className="bg-[#F5F5F5] rounded-[6px] p-[5px]"
        name="textSize"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setSize(e.target.value)
        }
        value={size}
        defaultValue={size}
      >
        {options.map((option: any) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </SettingCard>
  );
};

import dropdownArrow from "@assets/settingsDropdown.png";
import { comingSoonClick, trackEvent } from "@utils/analytics";
import * as React from "react";
import { useState } from "react";

export const SettingsCard = ({
  children,
  label,
  enabled,
}: {
  children: any;
  label: string;
  enabled: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(enabled);
  const handleClick = () => {
    if (!enabled) trackEvent(comingSoonClick, { type: "component_libraries" });
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`rounded-lg bg-designBackground text-base w-full m-[10px] bg-white ${
        enabled ? "" : "opacity-50"
      }`}
    >
      <Header
        isOpen={isOpen}
        handleClick={handleClick}
        label={label}
        enabled={enabled}
      />
      {isOpen ? children : ""}
    </div>
  );
};

const Header = ({
  handleClick,
  isOpen,
  label,
  enabled,
}: {
  handleClick: React.MouseEventHandler;
  isOpen: boolean;
  label: string;
  enabled: boolean;
}) => {
  return (
    <div
      onClick={handleClick}
      className={`rounded-t-md bg-[#007ACC] bg-opacity-5 items-center cursor-pointer p-[10px] flex flex-row justify-between border-l-blue-600 border-l-4`}
    >
      <div className="pl-[15px] text-[16px] font-[600]">{label}</div>
      {!enabled && (
        <button
          className="hidden sm:block rounded-xl bg-accent-blue text-[14px] font-[500] text-white pt-1 pb-1 pl-3 pr-3"
          style={{ backgroundColor: "#007ACC" }}
        >
          Coming soon
        </button>
      )}
      <img
        className={`w-[13px] h-[7px] mx-[10px] ${!isOpen && "-rotate-90"}`}
        src={dropdownArrow}
        alt=""
      />
    </div>
  );
};

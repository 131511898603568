import LoadingScreen from "@components/LoadingScreen";
import { identifyUser } from "@utils/analytics";
import { auth, getUserInfo } from "@utils/api";
import { fetchError } from "@utils/errors";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StateData } from "@utils/types";
import { setAuthToken } from "@store/plugin";
import { store } from "@store/index";
import { storeUser } from "@utils/auth";
import Survey from "../Survey";
import { ENABLE_MANDATE_PAYMENTS } from "@utils/consts";
const Login = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState();
  const [userAuthToken, setUserAuthToken] = useState<string>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const state: StateData = JSON.parse(searchParams.get("state") as string);
  const code = searchParams.get("code") as string;

  useEffect(() => {
    auth(state, code)
      .then((resp: any) => {
        setUserAuthToken(resp.data.accessToken);
      })
      .catch((error: any) => {
        navigate("/error", { state: { errordata: fetchError(error) } });
      });
  }, []);

  useEffect(() => {
    store.dispatch(setAuthToken(userAuthToken));
    if (userAuthToken) {
      localStorage.setItem("authToken", userAuthToken);
      getUserInfo(userAuthToken)
        .then((resp: any) => {
          const userInfo = {
            id: resp.data.id,
            handle: resp.data.handle,
            img_url: resp.data.img_url,
            email: resp.data.email,
          };

          identifyUser(userInfo);
          setUser(resp.data);
          storeUser(resp.data);
          // @Abbas is this way of navigation correct? It seems to have an warning in chrome console.
          // I saw it done below for survey so copypasted it.
          if (state.source.includes("webflow")) {
            navigate("/webflow", {
              state: { user: user, accessToken: userAuthToken },
            });
            return;
          }
          setIsAuthenticating(false);
          if (!resp.data.onboarding_completed || !ENABLE_MANDATE_PAYMENTS) {
            navigate("/survey", {
              state: { user: user, accessToken: userAuthToken },
            });
            return;
          } else if (
            !resp.data.payment_status ||
            !resp.data.payment_status.active
          ) {
            navigate("/survey?paymentStatus=init");
            return;
          } else {
            return;
          }
        })
        .catch((error: any) => {
          console.log("error fetching user:", error);
          navigate("/error", { state: { errordata: fetchError(error) } });
        });
    }
  }, [userAuthToken]);

  return isAuthenticating ? (
    <LoadingScreen message={"Authenticating"} />
  ) : (
    <Survey />
  );
};
export default Login;

import { store } from "@store/index";
import { setDocumentId, setFigmaUrl } from "@store/plugin";
import MessageHandler from "@helper/messageHandler";
import { MessageType } from "./types";
import { KeyVal } from "@helper/classes";

export function getDocumentId(figmaUrl: string) {
  let documentIdArray = figmaUrl.match("figma\\.com/file/([a-zA-Z0-9]+)");
  if (documentIdArray) {
    return documentIdArray[documentIdArray.length - 1];
  } else {
    return "";
  }
}

export const storeFigmaUrl = (url: string) => {
  const documentId = getDocumentId(url);
  store.dispatch(setFigmaUrl(url));
  store.dispatch(setDocumentId(documentId));
  MessageHandler.send(MessageType.SETPLUGINDATA, new KeyVal("figmaUrl", url));
  MessageHandler.send(
    MessageType.SETPLUGINDATA,
    new KeyVal("documentId", documentId)
  );
};

import Footer from "@components/Footer";
import { logout } from "@utils/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import CatImgG from "url:/@assets/cat_anim.gif";

export const AuthenticatingScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col h-screen ">
      <div className="px-[30px] py-[80px]">
        <img src={CatImgG} className="w-[110px] m-[-30px]" />
        <h2 className="text-[25px] font-medium py-[20px]">Logging in</h2>
        <div className="text-[16px] ">Please wait while we log you in</div>
        <div
          className="text-[14px] font-normal underline text-blue-500 cursor-pointer"
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          Cancel and Go Back
        </div>
      </div>
      <Footer />
    </div>
  );
};

import { To } from "react-router-dom";

export interface Size {
  height: number;
  width: number;
}
export interface Component {
  id: string;
  name: string;
  code: string;
  type: string;
  style: string;
}
// Store Data sent via Figma login
export interface StateData {
  // Who called the login
  source: string;
  // A unique code sent by the login caller
  checksum: string;
}

export interface StripeSessionInfo {
  signupInfo: SignupInfo;
  planId: string;
  signupFrom: string;
  state: string;
  successUrl: string;
  cancelUrl: string;
}

export interface SignupInfo {
  name: string;
  email: string;
  companyName?: string;
}

export interface IgnoredElement {
  id: string;
  nodeName: string;
}

export interface User {
  id: string;
  handle: string;
  img_url: string;
  email: string;
}

export interface Error {
  status: number;
  message: string;
}

export interface OtherLibraryComponent {
  uid: string;
  other: string;
  documentId: string;
}

export interface PMFitRating {
  uid: string;
  email: string;
  rating: number;
  nodeId: string;
  documentId: string;
}

export interface UserLibraryComponent {
  id?: number;
}

export interface Asset {
  name: string;
  url: string;
  type: string;
  node_id: string;
  fileName: string;
}

export interface VectorAssets {
  images: Map<string, string>;
}

export interface ComponentsMappings {
  [key: string]: ComponentMapping;
}

export interface ComponentMapping {
  id: string;
  name: string;
  mapping: Mapping;
  texts: Texts;
}

export interface Mapping {
  generate_code: boolean;
}

export interface Texts {
  [key: string]: string;
}

export interface ComponentProp {
  name: string;
  props: Props;
}

export interface Props {
  [key: string]: Prop;
}

export interface Prop {
  type: string;
  types: PropsType[];
  optional: boolean;
}

export interface PropsType {
  type: string;
  value: string;
  optional?: boolean;
}

export interface UserChoicePropType {
  type: string;
  value: any;
}

export interface LibraryComponent {
  id: number;
  name: string;
  library?: string;
  componentName?: string;
}

export interface Options {
  id: string;
  name: string;
  customLabel?: string;
}

export interface FigmaChild {
  value: string;
  label: string;
  customLabel?: string;
}

export interface SaveComponentsMapping {
  generate_code: boolean;
  component_id?: number;
  prop_map?: PropMap;
}

export interface PropMap {
  [key: string]: PropData;
}

export interface PropData {
  type: string;
  value?: string;
  values?: any[];
}

export interface InstallFirstTime {
  source: string;
}

export enum MessageType {
  // SEND SOME DATA FROM FIGMA TO PLUGIN
  FIGMA_DATA = "FIGMADATA",
  // INFORM PLUGIN TO SAVE DATA
  SAVE = "SAVE",
  // INFORM PLUGIN TO FETCH DATA
  FETCH = "FETCH",
  // INFORM PLUGIN TO DELETE DATA
  DELETE = "DELETE",
  // SELECTION EVENT
  SELECTION = "SELECTION",
  //GET NODE IMAGE
  IMAGE = "IMAGE",
  // RESIZE PLUGIN
  RESIZE = "RESIZE",
  // SET PLUGIN DATA
  SETPLUGINDATA = "SETPLUGINDATA",
  // GET PLUGIN DATA
  GETPLUGINDATA = "GETPLUGINDATA",
  // GET FILE NAME
  GETFILENAME = "GETFILENAME",
  // GET CURRENT USER
  GETCURRENTUSER = "GETCURRENTUSER",
}

export interface GlobalErrors {
  readonly [key: string]: ErrorScreenData;
}

export interface ErrorScreenData {
  error_code: string;
  graphic_url?: string;
  message?: string;
  buttons?: ErrorScreenButton[];
  sub_text?: string;
}

export interface ErrorScreenButton {
  text: string;
  redirect_url: To | number;
  button_type: string;
}

export interface StripeSubStatus {
  active: boolean;
  trial: boolean;
  plan_name?: string;
}

export interface UserInfo {
  id: string;
  email: string;
  handle: string;
  img_url: string;
  has_access: boolean;
  access_code: string;
  payment_status: StripeSubStatus;
}

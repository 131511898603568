import React, { Dispatch, SetStateAction } from "react";
import { logout } from "@utils/auth";
import { useNavigate } from "react-router-dom";

type LogoutProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const LogoutModal = ({ setOpen }: LogoutProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    setOpen(false);
    logout();
    navigate("/");
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-[18px] font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Are you sure you want to log out?
                  </h3>
                  <div className="mt-4">
                    <p className="text-[15px] text-gray-500">
                      You can log in again anytime by clicking on “Continue with
                      Figma”.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex flex-row">
              <button
                type="button"
                className="mx-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-black shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => handleLogout()}
              >
                Logout
              </button>
              <button
                type="button"
                className="mx-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-[#FFD803] px-4 py-2 text-base font-medium text-black shadow-sm hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => handleCancel()}
              >
                Stay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import CatLogo from "@assets/cat-withback.svg";
import FigmaLogo from "@assets/figma.png";

import { KeyVal, PluginMessage } from "@helper/classes";
import MessageHandler from "@helper/messageHandler";

import { AuthenticatingScreen } from "@components/AuthenticatingScreen";
import Footer from "@components/Footer";
import { GLOBAL_ERRORS } from "@utils/errors";
import {
  identifyUser,
  loginfail,
  loginscreen,
  loginwithfigma,
  trackEvent,
} from "@utils/analytics";
import { authListener, getUserInfo, sendinblueApi } from "@utils/api";
import { login, logout, storeUser } from "@utils/auth";
import {
  ENABLE_MANDATE_PAYMENTS,
} from "@utils/consts";
import { fetchError } from "@utils/errors";
import { MessageType, StateData, UserInfo } from "@utils/types";

import { selectAuthToken } from "@store/plugin";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redirectToFigmaAuth } from "@utils/redirects";

const Onboarding = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const authToken = useSelector(selectAuthToken);
  const navigate = useNavigate();

  useEffect(() => MessageHandler.send(MessageType.GETFILENAME, null), []);
  /**
   * Checks if
   *  - the auth token is valid
   *  - if authenticated then user has to be a figma plugin user thus
   *    we check if the auth token and the current user from figma plugin are the same
   * @param authToken the current auth token being used
   */
  const getUserFigmaInfo = async () => {
    let currentFigmaUser = await MessageHandler.pluginAsyncRequest(
      MessageType.GETCURRENTUSER,
      null
    );
    if (currentFigmaUser.data) {
      return {
        currentFigmaUserID: currentFigmaUser.data.id,
        currentFigmaUserName: currentFigmaUser.data.name,
      };
    } else {
      return null;
    }
  };

  const validateUser = async (authToken: string) => {
    setIsAuthenticating(true);
    let currentFigmaUserID = null;
    let currentFigmaUserName = null;

    getUserFigmaInfo().then((res) => {
      currentFigmaUserID = res?.currentFigmaUserID;
      currentFigmaUserName = res?.currentFigmaUserName;
    });

    let request = await getUserInfo(authToken);
    const user = request.data as UserInfo;
    if (request.status != 200 || !user || !user.id) {
      setIsAuthenticating(false);
      return;
    }
    if (currentFigmaUserID !== null && currentFigmaUserID !== user.id) {
      logout();
      let incorrectError = { ...GLOBAL_ERRORS["incorrectAccount"] };
      incorrectError.sub_text = incorrectError.sub_text?.replace(
        "{figma_name}",
        currentFigmaUserName
      );
      incorrectError.sub_text = incorrectError.sub_text?.replace(
        "{user_email}",
        user.email
      );
      incorrectError.sub_text = incorrectError.sub_text?.replace(
        "{user_name}",
        user.handle
      );
      navigate("/error", {
        state: { errordata: incorrectError },
      });
      return;
    } else {
      identifyUser(user);
      sendinblueApi(user.email, { LOGINSUCCESS: true });
      storeUser(user);
      if (!ENABLE_MANDATE_PAYMENTS) {
        navigate("/setup");
      } else if (
        ENABLE_MANDATE_PAYMENTS &&
        user.payment_status &&
        user.payment_status.active
      ) {
        navigate("/setup");
      } else {
        navigate("/buy/premium");
      }
      return;
    }
  };

  useEffect(() => {
    // @abbas: When does this happen?
    if (authToken === "") {
      MessageHandler.send(MessageType.FETCH, new KeyVal("authToken", ""));
    } else if (authToken === undefined) {
      // if no value found in client storage
      setIsAuthenticating(false);
    } else if (authToken) {
      // if authtoken available from cache check if auth token is valid
      validateUser(authToken);
    }
  }, [authToken]);

  const handleAuth = async () => {
    trackEvent(loginwithfigma);
    setIsAuthenticating(true);
    const state: StateData = {
      source: "ccplugin",
      checksum: Math.random().toString(36).substring(2, 9),
    } as StateData;

    redirectToFigmaAuth(state);

    authListener(state.checksum)
      .then((res) => login(res.data.token))
      .catch((error) => {
        trackEvent(loginfail);
        navigate("/error", { state: { errordata: fetchError(error) } });
        return;
      });
    setIsAuthenticating(false);
  };

  return isAuthenticating ? (
    <AuthenticatingScreen />
  ) : (
    <SignIn handleAuth={handleAuth} />
  );
};

const SignIn = ({ handleAuth }) => {
  trackEvent(loginscreen);
  return (
    <div className="flex h-screen justify-center items-center px-[44px]">
      <div className="flex flex-col align-center text-center">
        <img src={CatLogo} className="w-[113px] mb-[30px] mx-auto" />
        <div className="text-[18px] font-[700] mb-[10px]">
          Welcome to CopyCat Community Edition
        </div>
        <div className="text-[14px] font-[300] mb-[50px]">
          Generate code files from designs. Export component and layout code,
          assets and much more.
        </div>
        <button
          onClick={handleAuth}
          className="justify-center inline-flex bg-stone-900 items-center rounded-lg py-[12px] w-full"
        >
          <img src={FigmaLogo} className="w-[15px] mr-[15px]" />
          <span className="text-sm text-white">Get Started</span>
        </button>
      </div>

      <Footer />
    </div>
  );
};
export default Onboarding;

import React from "react";
import { stepsPropsType } from "..";
import "../Survey.css";
import PricingPlans from "@components/PricingPlans";

const SurveyStepFour = ({
  formData,
  setFormData,
  setActiveAlert,
}: stepsPropsType) => {
  const setData = (title: string) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        subscription: title,
      };
    });
  };

  return (
    <div className="stepBody stepfour">
      <div>
        <h1>4. Accelerate your UI development today!</h1>
        <div className="subTitle">
          <p>Choose design. Get Code. Meet deadlines.</p>
        </div>
      </div>
      <div className="flex">
        <PricingPlans />
      </div>
    </div>
  );
};

export default SurveyStepFour;

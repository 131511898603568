/**
This page displays what happens when a user subscription either suceedes or fails
**/
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import webflowsuccessplugin from "@assets/webflow/webflow-success.png";
import catpattern from "@assets/cat-pattern.png";
const Success = () => {
  return (
    <section
      style={{ backgroundImage: `url(${catpattern})` }}
      className="flex flex-col h-screen v-screen justify-center items-center"
    >
      <div className="flex flex-col justify-center items-center bg-white p-8 gap-6">
        <p className="text-3xl font-bold">Sucessfully Subscribed!</p>
        {/* <img src={webflowsuccessplugin} alt="success image" /> */}
        <iframe
          width="885"
          height="498"
          src="https://www.youtube.com/embed/OHHC9axkLVw"
          title="CopyCat - Figma to Webflow 0.2.0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
        <p className="text-xl">
          Open CopyCat on your webflow project to start converting designs!
        </p>
      </div>
    </section>
  );
};

const Fail = () => {
  const navigate = useNavigate();
  return (
    <section
      style={{ backgroundImage: `url(${catpattern})` }}
      className="flex flex-col h-screen v-screen justify-center items-center"
    >
      <div className="flex flex-col justify-center items-center bg-white p-8 gap-6">
        <p className="text-3xl font-bold">Subscription Failed/Cancelled!</p>
        <p className="text-xl">
          <span>
            Click on the button below to go back to pricing page. If you are
            having issues reachout to us at support@copycat.dev or use our
            support bot at{" "}
          </span>
          <a className="text-blue-500" href="https://copycat.dev">
            copycat.dev
          </a>
        </p>
        <button
          onClick={() => {
            navigate("/webflow");
          }}
          className="shadow-button-shadow justify-center inline-flex bg-[#FFD803] disabled:opacity-50 items-center rounded-lg py-[12px] w-full"
        >
          <span className="text-[16px] text-black font-[500]">Choose Plan</span>
        </button>
      </div>
    </section>
  );
};

const WebflowSubscriptionResult = () => {
  const { result } = useParams();
  if (result === "success") {
    return <Success />;
  } else {
    return <Fail />;
  }
};

export default WebflowSubscriptionResult;

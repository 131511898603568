import LogRocket from "logrocket";
import * as mixpanel from "mixpanel-figma";
import { User } from "./types";

//Mixpanel Event currently In Use
export const accesscodeInput = "accesscode-input";
export const loginscreen = "login-seen";
export const loginwithfigma = "login-click";
export const loginsuccess = "login-success";
export const loginfail = "login-fail";
export const logoutClick = "logout-click";
export const logoutSuccess = "logout-success";
export const joinSlack = "joinslack-click";
export const codeRating = "coderating-submit";
export const figmafileurlinputscreen = "fileurlinput-seen";
export const figmafileurlsubmit = "fileurlinput-submit";
export const figmafileurlsuccess = "fileurlinput-success";
export const figmafileurlfail = "fileurlinput-fail";
export const figmacodeconversionscreen = "pluginhome-seen";
export const figmadodesigntocode = "coderesult-processing";
export const codeResultAssetsSeen = "coderesultassets-seen";
export const codeResultStyles = "coderesultstyles-seen";
export const codeFeedback = "codefeedback-submit";
export const codeResultSeen = "coderesult-seen";
export const codeconversionsuccess = "coderesult-success";
export const codeconversionfail = "coderesult-fail";
export const figmacodeexported = "coderesultexport-click";
export const codeResultComponentSeen = "coderesultcomponent-seen";
export const codeResultPreview = "coderesultpreview-seen";
export const codeResultPreviewError = "coderesultpreview-error";
export const trialExpireSeen = "trialexpire-seen";
export const comingSoonClick = "comingsoon-click";
export const codeResultCopy = "coderesult-copy";
export const onboardingSubmitClick = "onboarding-submit-click";
export const onboardingContinueClick = "onboarding-continue-click";
export const onboardingSpecialityClick = "onboardingspeciality-click";
export const onboardingFamiliarityClick = "onboardingfamiliarity-click";
export const onboardingPurposeClick = "onboardingpurpose-click";
export const codeOutputSettingsSubmit = "codeoutputsettings-submit";
export const vscodeButtonClick = "vscodebutton-click";

//Mixpanel Event currently Not In Use
export const pluginClosed = "plugin-closed";
export const helpButtonClick = "helpbutton-click";
export const viewprivacypolicy = "loginprivacypolicy-click";
export const figmaviewloginhint = "loginhint-click";
export const figmasetitngsicon = "settings-seen";
export const tagComponentsSkipAll = "tagcomponent-skipall-click";
export const tagComponentsSkip = "tagcomponent-skip-click";
export const tagComponentsSeen = "tagcomponent-seen";
export const tagComponentsNext = "tagcomponent-next-click";
export const stylesUpload = "styles-upload";
export const codeResultEdit = "coderesult-edit";
export const codeOutputSettingsClick = "codeoutputsettings-click";
export const settingsBaseTextSizeDrag = "settingsbasetextsize-drag";
export const settingsMarginUnitSelect = "settingsmarginunit-select";
export const settingsPaddingUnitSelect = "settingspaddingunit-select";
export const settingsOutputLanguageSelect = "settingsoutputlanguage-select";
export const settingsStyleSheetLanguageSelect =
  "settingsstylesheetlanguage-select";
export const settingsSCSSComingSoonClick = "settingsscsscomingsoon-click";
export const settingsDesignSystemClick = "settingsdesignsystem-click";
export const settingsDesignSystemComingSoonClick =
  "settingsdesignsystemcomingsoon-click";
export const figmaFrameSubmit = "figmaframe-submit";
export const paywallSeen = "paywall-seen";
export const paywallCtaClick = "paywallcta-click";
export const paymentSuccess = "payment-success";

export function initAnalytics() {
  mixpanel.init(process.env.MIXPANEL_TOKEN);
  LogRocket.init(process.env.LOGROCKET_ID);
}

export function identifyUser(user: User) {
  mixpanel.identify(user.id);
  mixpanel.people.set({
    id: user.id,
    name: user.handle,
    email: user.email,
  });
  LogRocket.identify("User", {
    ...user,
  });
}

export function trackEvent(
  event: string,
  obj?: any,
  sourceType?: "plugin" | "browser" | "codeoutput" | "login"
): void {
  const source = {
    source: sourceType || "plugin",
  };
  const eventProperties = { ...obj, ...source };
  mixpanel.track(event, eventProperties);
}

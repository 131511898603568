import { KeyVal, PluginMessage } from "@helper/classes";
import { LOCAL_STORAGE_KEY, store } from "@store/index";
import { setAuthToken, setUser } from "@store/plugin";
import MessageHandler from "../helper/messageHandler";
import {
  loginsuccess,
  logoutClick,
  logoutSuccess,
  trackEvent,
} from "./analytics";
import { MessageType } from "./types";

export const login = (token: string) => {
  store.dispatch(setAuthToken(token));
  localStorage.setItem("authToken", token);
  const data = new KeyVal("authToken", token);
  const msg = new PluginMessage(MessageType.SAVE, data);
  MessageHandler.sendmsg(msg);
  trackEvent(loginsuccess);
};

export const logout = () => {
  trackEvent(logoutClick);
  store.dispatch(setAuthToken(undefined));
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  MessageHandler.send(MessageType.DELETE, { key: "authToken" });
  MessageHandler.send(MessageType.SETPLUGINDATA, new KeyVal("figmaUrl", ""));
  MessageHandler.send(MessageType.SETPLUGINDATA, new KeyVal("documentId", ""));
  MessageHandler.send(MessageType.DELETE, { key: "isScssUploaded" });
  trackEvent(logoutSuccess);
};

export const storeUser = (user: object) => {
  store.dispatch(setUser(user));
  MessageHandler.send(MessageType.SAVE, new KeyVal("user", user));
};

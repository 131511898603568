import copycatGuide from "url:/@assets/insertthelink.gif";
import HelpIcon from "@assets/helpIcon.png";
import Footer from "@components/Footer";
import LoadingScreen from "@components/LoadingScreen";
import { LogoutModal } from "@components/LogoutModal";
import { KeyVal } from "@helper/classes";
import MessageHandler from "@helper/messageHandler";
import AlertAnnouncement from "@components/AlertAnnouncement";
import PopupAnnouncement from "@components/PopupAnnouncement";
import {
  selectAuthToken,
  selectFigmaUrl,
  selectFileName,
  selectUser,
} from "@store/plugin";
import {
  figmafileurlfail,
  figmafileurlinputscreen,
  figmafileurlsubmit,
  figmafileurlsuccess,
  trackEvent,
} from "@utils/analytics";
import { postFigmaUrl, sendinblueApi } from "@utils/api";
import { MIN_LARGE_WINDOW_HEIGHT } from "@utils/consts";
import { fetchError } from "@utils/errors";
import { storeFigmaUrl } from "@utils/figmaUrl";
import { MessageType } from "@utils/types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";

const announcementsData = {
  alert_announcement:
    "React-bootstrap and react-mui are added to the Copycat plugin",
  release_notes: {
    version: "V5.2",
    notes: [
      "React-bootstrap and react-mui are added to the Copycat plugin",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit",
      "Excepteur sint occaecat cupidatat non proident",
    ],
  },
};

const Setup = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const authToken = useSelector(selectAuthToken);
  const figmaUrl = useSelector(selectFigmaUrl);
  const fileName = useSelector(selectFileName);
  const user = useSelector(selectUser);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (figmaUrl === "") {
      setIsProcessing(false);
      trackEvent(figmafileurlinputscreen);
    } else if (figmaUrl === undefined) {
      MessageHandler.send(
        MessageType.GETPLUGINDATA,
        new KeyVal("figmaUrl", "")
      );
      MessageHandler.send(
        MessageType.GETPLUGINDATA,
        new KeyVal("documentId", "")
      );
    } else if (figmaUrl) navigate("/ready");
  }, [figmaUrl]);

  useEffect(() => {
    if (!fileName || fileName === "") {
      MessageHandler.send(MessageType.FILENAME, null);
    }
  }, [fileName]);

  const validate = async (value: string) => {
    if (!user || !authToken || !fileName) return;
    setIsProcessing(true);
    if (validator.isURL(value)) {
      // TODO: Check here for documentID being persisted.
      postFigmaUrl(authToken, value, fileName)
        .then(() => {
          trackEvent(figmafileurlsuccess);
          sendinblueApi(user?.email, { FILEURLINPUTSUCCESS: true });
          storeFigmaUrl(value);
          navigate("/ready");
        })
        .catch((error: any) => {
          trackEvent(figmafileurlfail);
          navigate("/error", {
            state: { errordata: fetchError(error) },
          });
        });
    } else {
      setIsProcessing(false);
      trackEvent(figmafileurlfail);
      setErrorText("Select the url carefully before copying");
    }
  };

  return isProcessing ? (
    <LoadingScreen message="Processing" />
  ) : (
    <SetupUI
      validate={validate}
      setErrorText={setErrorText}
      errorText={errorText}
    />
  );
};

interface SetupUIProps {
  validate: Function;
  setErrorText: Function;
  errorText: string;
}

const SetupUI = ({ validate, setErrorText, errorText }: SetupUIProps) => {
  const [text, setText] = useState("");
  const [confirmLogout, setConfirmLogout] = useState(false);

  const handleInput = function () {
    if (text === "") setErrorText("Select the url carefully before copying");
    else if (text.length > 0) {
      trackEvent(figmafileurlsubmit);
      validate(text);
    }
  };

  // const handlePaste = async () => {
  //   try {
  //     let text = await navigator.clipboard.readText();
  //     setText(text);
  //   } catch (e) {}
  //   try {
  //     document.execCommand('paste');
  //     console.log('ghadimi');
  //   } catch (e) {}
  // };

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      //13 is the enter keycode
      handleInput();
    }
  };
  return (
    <>
      {confirmLogout && <LogoutModal setOpen={setConfirmLogout} />}
      <div className="flex-col w-full h-screen px-[30px] bg-[#F5F5F5]">
        <div className="inline-flex pt-[40px] items-center">
          <h2 className="w-[263px] text-[25px] font-[700] leading-[32px] text-black">
            Input the Figma File URL to Continue
          </h2>
          <div
            className="text-[14px] font-[700] underline text-[#0075FF] cursor-pointer flex-nowrap"
            onClick={() => setConfirmLogout(true)}
          >
            Log Out
          </div>
        </div>

        <div className="flex-col items-center py-2 pb-[35px] border-b-[3px] border-dashed border-[#000000f]">
          <div className="inline-flex mb-[20px] mt-[30px] justify-between rounded-xl shadow-lg bg-white w-full py-[12px] px-[20px]">
            <input
              autoFocus
              className="appearance-none w-full text-black leading-tight focus:outline-none"
              type={"url"}
              placeholder="File URL"
              onChange={(e) => setText(e.target.value)}
              // {...bindTrigger}
              // onMouseDown={(e) => pasteFunction(e)}
              onKeyUp={(e) => enterPressed(e)}
            />
            {/* <button
              className='text-[#0075FF] text-[14px]'
              onClick={() => handlePaste()}>
              Paste
            </button> */}
          </div>

          <button
            onClick={handleInput}
            className="shadow-button-shadow bg-[#FFD803] font-[700] hover:opacity-80 text-[16px] text-black py-[13px] px-4 rounded-2xl w-full"
            type="button"
          >
            Submit
          </button>
        </div>
        <div className="text-red-700">{errorText}</div>

        <GuidePreview />
      </div>
      <Footer />
    </>
  );
};

const GuidePreview = () => {
  const [isWindowSmall] = useState(
    window.outerHeight < MIN_LARGE_WINDOW_HEIGHT
  );
  const [showPopup, setShowPopup] = useState(false);

  const icon = HelpIcon;
  const PopupAnnouncementStatus = false;
  const alertAnnouncementStatus = false;

  return (
    <>
      {PopupAnnouncementStatus && (
        <PopupAnnouncement data={announcementsData.release_notes} />
      )}
      {alertAnnouncementStatus && (
        <AlertAnnouncement
          announcement={announcementsData.alert_announcement}
        />
      )}
      <h2 className="text-[16px] mt-[40px] font-[600] text-black inline-flex">
        Can't find the URL?
        {isWindowSmall && (
          <img
            src={icon}
            className="ml-[10px] h-[24px]"
            onClick={() => setShowPopup(!showPopup)}
          />
        )}
      </h2>
      {isWindowSmall ? (
        <div
          className={`${
            showPopup ? "absolute z-10" : "hidden"
          }  h-full w-full top-0 left-0 px-[25px] py-[25%] bg-black bg-opacity-50`}
        >
          <div className="text-[16px] font-[400] text-white items-center">
            Simply click on the “chain icon” on the navbar and it will copy
            project’s link.
            <span
              className="text-white float-right cursor-pointer"
              onClick={() => setShowPopup(!showPopup)}
            >
              X
            </span>
          </div>
          <img src={copycatGuide} className="rounded-[5px]" />
        </div>
      ) : (
        <>
          <div className="text-[16px] font-[400]">
            Simply click on the “chain icon” on the navbar and it will copy
            project’s link.
          </div>
          <img src={copycatGuide} className="rounded-[5px] my-[20px]" />
        </>
      )}
    </>
  );
};

export default Setup;

import MessageHandler from "@helper/messageHandler";
import { MIN_LARGE_WINDOW_HEIGHT } from "@utils/consts";
import { MessageType } from "@utils/types";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const smallViewWidth = 390;

const ResizeWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    let height = 735;
    if (window.outerHeight < MIN_LARGE_WINDOW_HEIGHT) {
      height = 440;
    }
    MessageHandler.send(MessageType.RESIZE, {
      height,
      width: smallViewWidth,
    });
  }, [location]);
  return <div className="overflow-y-auto">{[children]}</div>;
};

export default ResizeWrapper;

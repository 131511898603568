import CodeSlash from "@assets/code-slash.svg";
import Profile from "@assets/profile.png";
import GearIcon from "@assets/settingsIcon.png";
import HelpIcon from "@assets/helpIcon.png";
import { LogoutModal } from "@components/LogoutModal";
import { PluginResponse } from "@helper/classes";
import PluginMessageHandler from "@helper/messageHandler";
import { selectUser } from "@store/plugin";
import {
  figmacodeconversionscreen,
  figmadodesigntocode,
  trackEvent,
} from "@utils/analytics";
import {
  ENABLE_MANDATE_PAYMENTS,
  frameSelectionVideo,
  manageAccountStripeUrl,
  MIN_LARGE_WINDOW_HEIGHT,
} from "@utils/consts";
import { MessageType } from "@utils/types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CatImgG from "url:/@assets/cat_anim.gif";
import gem from "@assets/gem.svg";
import { openInNewTab } from "@utils/redirects";

const Ready = () => {
  const user = useSelector(selectUser);
  const [figmaElementId, setFigmaElementId] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [confirmLogout, setConfirmLogout] = useState(false);
  const location = useLocation();
  //@ts-ignore
  const navigateFromSettings = location?.state?.navigateFromSettings;
  const navigate = useNavigate();

  const onSelection = (resp: PluginResponse) => {
    setImageLoading(true);
    if (resp.error) {
      selectionError(resp.error);
      return;
    }
    const nodeID = resp.data;
    setFigmaElementId(nodeID);
    PluginMessageHandler.pluginAsyncRequest(MessageType.IMAGE, {
      nodeID: nodeID,
      name: nodeID,
      format: "PNG",
    }).then(gotImage, failedToGetImage);
  };

  const selectionError = (data: any) => {
    setError("Multiple selections found. Please select a single frame");
    setImage("");
    setImageLoading(false);
  };

  const gotImage = (resp: PluginResponse) => {
    if (!resp.data) {
      setError("Failed to get Image");
      setImage("");
    } else {
      const imageUrl = Buffer.from(resp.data.image).toString("base64");
      setImage(imageUrl);
      setError("");
    }
    setImageLoading(false);
  };

  const failedToGetImage = (resp: PluginResponse) => {
    console.error("Failed to get image", resp);
    setError("Failed to get Image");
    setImage("");
    setImageLoading(false);
  };

  useEffect(() => {
    trackEvent(figmacodeconversionscreen);
    PluginMessageHandler.subscribe(MessageType.SELECTION, onSelection, false);
  }, []);

  const handleClick = (type: string) => {
    trackEvent(figmadodesigntocode);
    if (false && (!user?.days_remaining || user?.days_remaining <= 0))
      navigate("/trial");
    else {
      navigate("/pregeneration", { state: { figmaElementId } });
    }
  };
  console.log(user);
  return (
    <div className="h-screen w-full">
      <div className="flex flex-row p-[30px] border-b-2">
        <img src={user?.img_url || Profile} className="h-[48px] rounded-full" />
        <div className="px-[16px] text-[18px] font-[700]">
          Welcome back {user?.handle.split(" ")[0]}!
          <br />
          {ENABLE_MANDATE_PAYMENTS &&
            user?.payment_status &&
            user?.payment_status.active && (
              <>
                <div className="flex items-center">
                  <img src={gem} alt="" className="w-[18px]" />
                  <span className="ml-1 text-[#AF66DA] font-[500]">
                    Premium Member
                  </span>
                </div>
                <span
                  className="text-[14px] font-[700] underline text-[#6930CA] cursor-pointer mr-[8px]"
                  onClick={() => openInNewTab(manageAccountStripeUrl)}
                >
                  Manage Account
                </span>
              </>
            )}
          <span
            className="text-[14px] font-[700] underline text-[#6930CA] cursor-pointer"
            onClick={() => setConfirmLogout(true)}
          >
            Log Out
          </span>
        </div>
        {confirmLogout && <LogoutModal setOpen={setConfirmLogout} />}
        <div className={`absolute block top-[40px] right-[25px]`}>
          <button
            className={`align-bottom float-right h-[28px] w-[28px]`}
            onClick={() => {
              navigate("/settings", {
                state: { figmaElementId },
              });
            }}
          >
            <img src={GearIcon} className="h-[28px]" />
          </button>
        </div>
      </div>
      <div className="flex-col p-[30px]">
        <div className="text-[16px] font-[700] mb-[20px] text-[#555555] mr-[55px]">
          Start converting Figma to React in one simple click
        </div>

        <ImagePreviewWrapper
          imageLoading={imageLoading}
          image={image}
          error={error}
        />

        <div
          className={`font-[300] mt-[8px] text-[14px] italic ${
            error.length ? "text-[#E7006F]" : "text-[#777777]"
          } `}
        >
          {error.length && !imageLoading
            ? error
            : imageLoading
            ? "Preparing the frame..."
            : image.length
            ? "You can change frames by selecting another"
            : navigateFromSettings
            ? "Setting changed, please select the frame again"
            : "No Frame Selected"}
        </div>
      </div>
      <div className="fixed flex w-full gap-[20px] bottom-0 p-[30px]">
        <button
          disabled={!image.length}
          onClick={() => handleClick("Code")}
          className="cursor-pointer  shadow-button-shadow justify-center inline-flex bg-[#FFD803] disabled:opacity-50 items-center rounded-lg py-[12px] w-full"
        >
          <img src={CodeSlash} className="mr-[10px]" />
          <span className="text-[16px] text-black font-[500]">View Code</span>
        </button>
      </div>
    </div>
  );
};
const ImagePreviewWrapper = ({ imageLoading, image, error }) => {
  const [isWindowSmall] = useState(
    window.outerHeight < MIN_LARGE_WINDOW_HEIGHT
  );
  const [showPopup, setShowPopup] = useState(false);

  const heading = image.length
    ? "Selected Frame:"
    : "Select a frame or component";
  const icon = HelpIcon;

  return (
    <>
      <div className="text-[18px] font-medium mt-[40px] mb-[20px] leading-[21px]">
        <span className="inline-flex items-center">
          {heading}
          {isWindowSmall && (
            <img
              src={icon}
              className="ml-[10px] h-[24px]"
              onClick={() => setShowPopup(!showPopup)}
            />
          )}
        </span>
      </div>
      {isWindowSmall ? (
        <div
          className={`${
            showPopup ? "absolute z-10" : "hidden"
          }  h-full w-full top-0 left-0 px-[25px] py-[25%] bg-black bg-opacity-50`}
        >
          <span className="text-white">
            {image.length
              ? "Selected Frame"
              : "How to Select a Frame or Component"}
          </span>
          <span
            className="text-white float-right cursor-pointer"
            onClick={() => setShowPopup(!showPopup)}
          >
            X
          </span>
          <ImagePreview
            imageLoading={imageLoading}
            image={image}
            error={error}
            isWindowSmall={true}
          />
        </div>
      ) : (
        <ImagePreview
          imageLoading={imageLoading}
          image={image}
          error={error}
          isWindowSmall={false}
        />
      )}
    </>
  );
};

const ImagePreview = ({ imageLoading, image, error, isWindowSmall }) => {
  return imageLoading ? (
    <div className="flex w-[330px] h-[190px] bg-[#D9D9D9] justify-center">
      <img src={CatImgG} />
    </div>
  ) : image.length ? (
    <img
      src={"data:image/png;base64, " + image}
      className={`m-auto p-2 border-2 ${
        isWindowSmall ? "max-h-[200px]" : "max-h-[330px]"
      } ${error.length ? "border-[#E7006F]" : ""}`}
    />
  ) : (
    <video
      className={`my-[10px] ${
        error.length ? "border-[1px] border-[#E7006F] rounded-[5px]" : ""
      }`}
      loop
      src={frameSelectionVideo}
      autoPlay
      muted
    />
  );
};

export default Ready;

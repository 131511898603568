import CloseIcon from "@assets/close-icon.png";
import RatingIcon0 from "@assets/rating-0.png";
import RatingIcon1 from "@assets/rating-1.png";
import RatingIcon2 from "@assets/rating-2.png";
import RatingIcon3 from "@assets/rating-3.png";
import { selectUser } from "@store/plugin";
import { codeRating, trackEvent } from "@utils/analytics";
import { sendinblueApi } from "@utils/api";
import React from "react";
import { useSelector } from "react-redux";

// The container with the button to show everywhere
export const RatingPage = ({
  setOpen,
  setPage,
  setRating,
}: {
  setOpen: Function;
  setPage: Function;
  setRating: Function;
}) => {
  const user = useSelector(selectUser);
  const userEmail = user?.email || "unknown";
  return (
    <div
      className={`bg-white rounded-xl py-[14px] px-[17px] h-[104px] w-[361px] text-center align-middle`}
    >
      <div className="flex flex-row justify-between">
        <div className="font-medium text-[13px]">
          Are these results useful and acceptable?
        </div>
        <div onClick={() => setOpen(false)} className="">
          <img src={CloseIcon} className="h-[20px]" />
        </div>
      </div>
      <div className="flex flex-row justify-between mt-[15px] mr-[20px]">
        <img
          src={RatingIcon3}
          className="h-[40px]"
          onClick={() => {
            trackEvent(codeRating, { rating: 4 });
            sendinblueApi(userEmail, { CODERATING: 4 });
            setRating(4);
            setPage(2);
          }}
        />
        <img
          src={RatingIcon2}
          className="h-[40px]"
          onClick={() => {
            trackEvent(codeRating, { rating: 3 });
            sendinblueApi(userEmail, { CODERATING: 3 });
            setRating(3);
            setPage(2);
          }}
        />
        <img
          src={RatingIcon1}
          className="h-[40px]"
          onClick={() => {
            trackEvent(codeRating, { rating: 2 });
            sendinblueApi(userEmail, { CODERATING: 2 });
            setRating(2);
            setPage(2);
          }}
        />
        <img
          src={RatingIcon0}
          className="h-[40px]"
          onClick={() => {
            trackEvent(codeRating, { rating: 1 });
            sendinblueApi(userEmail, { CODERATING: 1 });
            setRating(1);
            setPage(2);
          }}
        />
      </div>
    </div>
  );
};

import { SlackButtonView } from "@components/SlackButton";
import React from "react";
import CatImgG from "url:/@assets/cat_anim.gif";

const LoadingScreen = ({
  message,
  cancelHref,
}: {
  message: string;
  cancelHref?: string;
}) => {
  let cancelText = "Cancel and Go Back";
  if (!cancelHref) cancelHref = "/setup";
  if (message == "Authenticating") {
    cancelText = "Cancel and Go Back To Login Screen";
    cancelHref = "/";
  }

  return (
    <div className="flex flex-col h-screen justify-evenly">
      <div className="grid justify-center m-auto px-16">
        <img src={CatImgG} className="px-2 h-[16rem]" />
        <h2 className="text-xl text-center">{message}</h2>
      </div>
      <div className="mb-10">
        <h1 className="text-base text-center text-black">
          Have feedback?
          <br />
          Join our community.
        </h1>
        <SlackButtonView />
      </div>
      <div className="absolute top-5 right-8">
        <a className="text-stone-400" href={cancelHref}>
          {cancelText}
        </a>
      </div>
    </div>
  );
};

export default LoadingScreen;

import { RadioInput } from "@components/Settings/RadioInput";
import { UnitsInput } from "@components/Settings/UnitsInput";
import { selectUser } from "@store/plugin";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  languageOptions,
  libraryOptions,
  stylesheetOptions,
  unitsOptions,
} from "../../utils/settingsMetadata";

type settingsProps = {
  onPageCall: boolean;
  baseTextSize: number;
  basePadding: number;
  baseMargin: number;
  setUILibrary: Function;
  styleSheetSelector: any;
  setBaseTextSize: Function;
  setBaseMarginUnit: Function;
  setBasePaddingUnit: Function;
  setLanguageSelector: Function;
  setStyleSheetSelector: Function;
};

const OutputConfiguration = ({
  setUILibrary,
  baseTextSize,
  basePadding,
  baseMargin,
  styleSheetSelector,
  setBaseTextSize,
  setBaseMarginUnit,
  setBasePaddingUnit,
  setLanguageSelector,
  setStyleSheetSelector,
}: settingsProps) => {
  const user = useSelector(selectUser);

  return (
    <div className="p-[25px]">
      <div className="text-[14px] font-[600] mb-[2px]">UI Library</div>
      <div className="flex flex-wrap items-center">
        {libraryOptions.map((option) => (
          <RadioInput
            attribute={"library_component_id"}
            option={option}
            user={user}
            setOption={setUILibrary}
            additionalClasses={"w-1/2"}
          />
        ))}
      </div>
      <hr className="my-[11px] border-black opacity-10" />
      <div className="text-[14px] font-[600] mb-[2px]">Output Language</div>
      <div className="flex flex-wrap items-center">
        {languageOptions.map((option) => (
          <RadioInput
            additionalClasses={"w-1/2"}
            attribute={"outputLanguage"}
            option={option}
            user={user}
            setOption={setLanguageSelector}
          />
        ))}
      </div>
      <hr className="my-[11px] border-black opacity-10" />
      <div className="text-[14px] font-[600] mb-[2px]">
        Style Sheet Language
      </div>
      <div className="flex flex-wrap items-center">
        {stylesheetOptions.map((option) => (
          <RadioInput
            additionalClasses={"min-w-[50%]"}
            attribute={"StyleSheetConfig"}
            option={option}
            user={user}
            setOption={setStyleSheetSelector}
          />
        ))}
        {!styleSheetSelector.enabled ? (
          <button
            className="rounded-xl bg-accent-blue py-0 text-[14px] font-[500] text-white pl-2 pr-2 opacity-80"
            style={{ backgroundColor: "#007ACC" }}
          >
            Coming soon
          </button>
        ) : null}
      </div>
      <div className="flex flex-col">
        {!styleSheetSelector.enabled ? (
          <div className="mt-[10px]">
            This feature is not here yet, we will release it soon!
          </div>
        ) : null}
      </div>
      <hr className="my-[20px] border-black opacity-10" />
      <div className="flex flex-col justify-between">
        {false && (
          <div className="mb-8">
            <div className="mb-[10px] text-[14px] font-[600]">Preview</div>
            <div
              className="border-black border-2 rounded-[10px]"
              style={{
                fontSize: `${baseTextSize}px`,
                padding: `${basePadding}px`,
                margin: `${baseMargin}px`,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
        )}
        <div className="text-[14px] font-[600] mb-[11px]">Base Text Size</div>
        <div className="relative flex flex-row items-center w-full max-w-[375px]">
          <input
            type="range"
            className="mr-[20px] cursor-pointer form-range w-3/4 h-[2px] p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
            id="customRange1"
            max="36"
            min="1"
            value={baseTextSize}
            onChange={(e) => {
              setBaseTextSize(e.target.value);
            }}
          />
          <span
            className="text-[12px] font-[500] rounded bg-[#F5F5F5] text-black px-[15px] py-[6px]"
            style={{ boxShadow: "0px 4px 10px 0px #00000012" }}
          >
            {baseTextSize}px
          </span>
        </div>
      </div>
      <hr className="my-[11px] border-black opacity-10" />
      <div className="flex flex-row justify">
        <UnitsInput
          {...unitsOptions[0]}
          setUnit={setBaseMarginUnit}
          attribute={"marginUnit"}
          user={user}
        />
        <UnitsInput
          {...unitsOptions[1]}
          setUnit={setBasePaddingUnit}
          attribute={"paddingUnit"}
          user={user}
        />
      </div>
    </div>
  );
};

export default OutputConfiguration;

import ResizeWrapper from "@components/ResizeWrapper";
import { PluginResponse } from "@helper/classes";
import PluginMessageHandler from "@helper/messageHandler";
import { store } from "@store/index";
import { setState } from "@store/plugin";
import { initAnalytics } from "@utils/analytics";
import { MessageType } from "@utils/types";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ErrorScreen from "./views/ErrorScreen";
import CodeGen from "./views/CodeGen";
import CodeResults from "./views/CodeResults";
import Login from "./views/Login";
import Onboarding from "./views/Onboarding";
import Ready from "./views/Ready";
import RequestAccess from "./views/RequestAccess";
import Setup from "./views/Setup";
import Trial from "./views/Trial";
import VsCodeTrigger from "./views/VsCodeTrigger";
import Settings from "./views/Settings";
import Survey from "./views/Survey";
import WebflowLogin from "./views/Webflow/WebflowLoginSuccess";
import WebflowSubscriptionResult from "./views/Webflow/SubscriptionResult";
import CodegenBrowser from "./views/CodeGenBrowser";
import Logout from "./views/Logout";
import Reset from "./views/Reset";
import BuyPremium from "./views/BuyPremium";
import PreGeneration from "./views/PreGeneration";

// listen to store messages
PluginMessageHandler.subscribe(MessageType.SAVE, (message: PluginResponse) => {
  if (message.data) {
    store.dispatch(
      setState({ key: message.data.key, value: message.data.value })
    );
  }
});
const App = () => {
  initAnalytics();
  return (
    <ResizeWrapper>
      <Routes>
        <Route path="/" element={<Onboarding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/requestAccess" element={<RequestAccess />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/buy/premium" element={<BuyPremium />} />
        <Route path="/pregeneration" element={<PreGeneration />} />
        {/* <Route path="/paymentCancel" element={<Survey step={4} />} />
        <Route path="/paymentSuccess" element={<Survey step={4} />} /> */}
        <Route path="/error" element={<ErrorScreen />} />
        <Route path="/ready" element={<Ready />} />
        <Route
          path="/trial"
          element={<Trial withPlans={false} testimonial />}
        />
        <Route
          path="/settings"
          element={
            <Settings
              setOpenSettings={() => {}}
              setUpdatingSettings={() => {}}
            />
          }
        />
        <Route
          path="/vscode/:sessionId/:buildSessionId/:authToken"
          element={<VsCodeTrigger />}
        />
        <Route path="/codegen/:node_id" element={<CodeGen />} />
        <Route path="/coderesults" element={<CodeResults />} />
        <Route path="/webflow" element={<WebflowLogin />} />
        <Route path="/webflow/subscription" element={<WebflowLogin />} />
        <Route
          path="/webflow/subscription/:result"
          element={<WebflowSubscriptionResult />}
        />
        <Route
          path="/codegenBrowser/:authToken/:sessionId"
          element={<CodegenBrowser />}
        />
      </Routes>
    </ResizeWrapper>
  );
};

export default App;

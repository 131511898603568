import MessageHandler from "@helper/messageHandler";
import { MessageType } from "@utils/types";
import React, { useEffect } from "react";
import { KeyVal } from "@helper/classes";
import { useNavigate } from "react-router-dom";

const resetPluginData = () => {
  MessageHandler.send(MessageType.SETPLUGINDATA, new KeyVal("figmaUrl", ""));
  MessageHandler.send(MessageType.SETPLUGINDATA, new KeyVal("documentId", ""));
};

const Reset = () => {
  const navigate = useNavigate();

  useEffect(() => {
    resetPluginData();
    navigate("/");
  }, []);

  return <div>...</div>;
};
export default Reset;

import { comingSoonClick, trackEvent } from "@utils/analytics";
import * as React from "react";

type stylesheetOption = {
  label: string;
  value: string | number | null;
  enabled: boolean;
  logo: any | null;
};

type styleInputProps = {
  option: stylesheetOption;
  setOption: Function;
  user: any;
  attribute: string;
  additionalClasses: string;
};

export const RadioInput = ({
  option,
  setOption,
  user,
  attribute,
  additionalClasses,
}: styleInputProps) => {
  return (
    <div className={`flex flex-row items-center my-[9px] ${additionalClasses}`}>
      <input
        defaultChecked={user?.user_config[attribute] == option.value}
        type="radio"
        disabled={!option.enabled}
        name={attribute}
        className="w-[18px] h-[18px] mr-[15px] rounded-full"
        value={option.value || "None"}
        onChange={(e) => {
          setOption(option);

          if (!option.enabled)
            trackEvent(comingSoonClick, {
              type: option.value,
            });
        }}
      ></input>
      <div className="space-x-1 inline-flex items-center">
        {option.logo && (
          <img
            src={option.logo}
            className="w-[18px] h-[18px]"
            alt="style logo"
          ></img>
        )}
        <div
          className={`text-[14px] font-[400] ${
            option.enabled ? "" : "line-through"
          }`}
        >
          {option.label}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import bxsquoteAltLeft from "@assets/bxsquoteAltLeft.svg";
import grantVine from "@assets/grant-vine.svg";
import group1308 from "@assets/group1308.svg";
import footerStyles from "./Footer.module.css";

interface Props {
  showTestimonials?: boolean;
}

const Footer: React.FC<Props> = ({ showTestimonials = true }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 py-6">
      <div>
        <span>Trusted by:</span>
        <img className={footerStyles["group-1308"]} src={group1308} />
      </div>
      {showTestimonials && (
        <div className={footerStyles["group-1314"]}>
          <div className={footerStyles["rectangle-578"]}>
            <div className={footerStyles["flex-container"]}>
              <img
                className={footerStyles["bxsquote-alt-left"]}
                src={bxsquoteAltLeft}
              />
              <span>
                We have been shipping UI way ahead of time since CopyCat
              </span>
            </div>
            <div className="flex gap-2">
              <img className={footerStyles["ellipse-41"]} src={grantVine} />
              <div className={footerStyles["flex-container-1"]}>
                <span className={footerStyles["anna-smith"]}>Grant Vine</span>
                <span className={footerStyles["tech-lead-at-oracle"]}>
                  CTO at Finclusion Group
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;

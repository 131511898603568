export const figmaOauthUrl = process.env.FIGMA_OAUTH_URL as string;
export const backendUrl = process.env.BACKEND_URL as string;
export const frontendUrl = process.env.FRONTEND_URL as string;
export const codegenUrl = process.env.CODEGEN_URL as string;
export const buildURL = process.env.BUILD_SERVER_URL as string;
export const previewURL = process.env.PREVIEW_SERVER_URL as string;
export const stripeWebflowPlanID = process.env.STRIPE_WEBFLOW_PLAN_ID as string;
export const browserCopyUrlVideo =
  "https://storage.googleapis.com/copycatcdn/plugin-assets/videos/copy-url.mp4";
export const figmaCopyUrlVideo =
  "https://storage.googleapis.com/copycatcdn/plugin-assets/videos/figma-url.mp4";
export const frameSelectionVideo =
  "https://storage.googleapis.com/copycatcdn/plugin-assets/videos/frame-selection-aug16.mp4";
export const copycatGuide =
  "https://res.cloudinary.com/dl0uavbsl/image/upload/v1664808895/Figma_app_share_wl32a8.gif";
export const figmaLink = "https://figma.com";
export const slackLink =
  "https://copycat-community.slack.com/join/shared_invite/zt-1dunxaucn-1s~I9MMePbHJuJQO0brcUA#/shared-invite/email";

export const corsUrl = (originalUrl: string) =>
  backendUrl + "/proxy/" + encodeURIComponent(originalUrl);

export const SENDINBLUE_API_KEY = process.env.SENDINBLUE_API_KEY || "";
export const sendInBlueLink = "https://api.sendinblue.com/v3/contacts";
export const STRIPE_WEBFLOW_PLAN_ID = process.env.STRIPE_WEBFLOW_PLAN_ID || "";
export const authParams = {
  client_id: process.env.CLIENT_ID,
  redirect_uri: process.env.REDIRECT_URI,
  scope: `file_read`,
  response_type: `code`,
  type: `plugin`,
};

export const filetype = {
  js: "code",
  tsx: "code",
  ts: "code",
  jsx: "code",
  css: "code",
  scss: "code",
  gif: "image",
  png: "image",
  svg: "image",
  jpeg: "image",
};

export const languages = {
  js: "javascript",
  css: "css",
  scss: "scsss",
  tsx: "tsx",
  jsx: "jsx",
  ts: "typescript",
};

export const MIN_LARGE_WINDOW_HEIGHT = 850;

// TODO: Need to fetch this data from API.
export const MUI_LIBRARY_COMPONENT_ID = process.env.MUI_LIBRARY_COMPONENT_ID;
export const BOOTSTRAP_LIBRARY_COMPONENT_ID =
  process.env.BOOTSTRAP_LIBRARY_COMPONENT_ID;
export const stripePaymentSuccessRedirect =
  frontendUrl +
  "/survey?paymentStatus=success&triggerSource={{triggerSource}}&planId={{planId}}&planPrice={{planPrice}}";
export const stripePaymentCancelRedirect =
  frontendUrl +
  "/survey?paymentStatus=cancel&triggerSource={{triggerSource}}&planId={{planId}}&planPrice={{planPrice}}";
export const manageAccountStripeUrl =
  "https://subscription.stripe.copycat.dev/p/login/4gw7uVcnJfbIeSQ3cc";
export const ENABLE_MANDATE_PAYMENTS =
  process.env.ENABLE_MANDATE_PAYMENTS === "true" || false;
export const chatWidgetLink =
  "https://tawk.to/chat/623da9c50bfe3f4a876fac22/1fv0dub40";
